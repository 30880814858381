import React, {FC, useEffect, useRef} from 'react';
import List from '../UI/List';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

interface IInfoSection{
    className?: string;
    active:boolean;
    header?: string;
    description?: React.ReactNode;
    firstListHeader?: React.ReactNode;
    firstList?: React.ReactNode[];
    secondListHeader?: React.ReactNode;
    secondList?: React.ReactNode[];
}

const InfoSection:FC<IInfoSection> = ({active,className='',header,description,firstListHeader,secondListHeader,firstList=[],secondList=[]}) => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.5, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.5, stagger: 0.5};
/*        timeline.fromTo(".info-section__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".info-section__description", UpStart, InFinish, 0.3)
        timeline.fromTo(".info-section__content", UpStart, InFinish, 0.7)
        timeline.fromTo(".info-section__list.left", UpStart, InFinish, 1.0)
        timeline.fromTo(".info-section__list.right", UpStart, InFinish, 1.0)*/
        ;

    },[refTarget]);
    return (
        <div className={`info-section ${className} ${active ? "info-section-active": "info-section-hidden"}`} ref={refTarget}>
            <div className="container">
                <div className="info-section__wrap">
                    {header && <div className="info-section__header">
                        <h2 className="h2">{header}</h2>
                    </div>}
                    {description &&
                        <div className="info-section__description">
                            {description}
                        </div>
                    }
                    <div className="info-section__content">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="info-section__list left">
                                    {firstListHeader &&   <div className="info-section__header-list">{firstListHeader}</div>}

                                    {firstList.length > 0 && <List className={'list-strips'} items={firstList}/>}

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="info-section__list right">
                                    {secondListHeader &&   <div className="info-section__header-list">{secondListHeader}</div>}
                                    {secondList.length > 0 && <List className={'list-strips'} items={secondList}/> }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoSection;