import React, {FC, useEffect, useRef, useState} from 'react';
import Logo from "./icons/Logo";
import {Link, NavLink} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx, useSelectorEx} from "../hooks/redux";
import { changeOpenMenu } from '../redux/slices/appSlice';
gsap.registerPlugin(ScrollTrigger);

interface IHeader {
    className?: string;
    attach?:boolean;
    out?: boolean;
}
const Header:FC<IHeader> = ({className='',attach,out}) => {
    const preloader = useSelectorEx(state => state.app.preloader)
    const [open,setOpen] = useState(false);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
          /*  scrollTrigger:{
                trigger: refTarget.current,
               // start: "top top",
            }*/
        });
        if(!preloader) {
            const UpStart = {autoAlpha: 0, opacity: 0, duration: 0.5, stagger: 0.5};
            const InFinish = {autoAlpha: 1, opacity: 1, duration: 0.5, clearProps: "all", stagger: 0.5};
            timeline.fromTo(".header", UpStart, InFinish, 0.1)
            timeline.fromTo(".logo__img", UpStart, InFinish, 0.7)
            ;
        }
    },[preloader]);

    const dispatch = useDispatchEx();
    const onChangeMenu = ()=>{
        setOpen(!open);
        dispatch(changeOpenMenu(!open));
    }
    const toService = ()=>{
        setOpen(false);
        dispatch(changeOpenMenu(false));
        document.getElementById('services')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }
    return (
        <header className={`header ${attach ? 'active' : ''} ${out ? 'out' : ''}`} ref={refTarget}>
            <div className="header__inner">
                <div className="container">
                    <div className="header__wrap">
                        <div className="header__logo">
                            <Link to={'/'}>
                                <Logo />
                            </Link>

                        </div>
                        <div className="header__menu">
                            <nav className={'menu'}>
                                <ul className={`menu__wrap ${open ? "active" : ""}`}>
                                    <li className={'menu__item'}><NavLink to="/">UBER UNS</NavLink></li>
                                    <li className={'menu__item'}><Link to={'/#services'} onClick={toService}>LEISTUNGEN</Link></li>
                                    <li className={'menu__item'}><NavLink to="/jobs">KARRIERE</NavLink></li>
                                </ul>
                                <div className={`menu__burger ${open ? "active" : ""}`} onClick={onChangeMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;