import React, {useState} from 'react';
import Button from "./UI/Button";
import {gtagEvent} from "../helpers/scripts";

const ButtonSpread = () => {
    const [open,setOpen] = useState(false);
    return (
        <div className={'button-spread'}>
            {open ? <>
                <Button onClick={()=>{gtagEvent('click','contact_per_email')}} type={'htmlLink'} href={"mailto:info@adr-trans.de"} className={'btn-small contact-section__btn'} name={'Kontakt per E-Mail'}/>
                <Button onClick={()=>{gtagEvent('click','contact_per_whatsapp')}} type={'htmlLink'} href={"https://wa.me/49017670066644"} className={'btn-small contact-section__btn'} name={'Kontakt per Whatsapp'}/>
            </>
                :
                <Button onClick={()=>setOpen(true)} className={'btn-small'} name={'Bewerben'} />}

        </div>
    );
};

export default ButtonSpread;