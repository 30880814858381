export function numberWithCommas(x:any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

interface MetricValues {
    event_category?: string;
    event_label?: string;
    value?: any;
}

export const gtagEvent = (action: string, category?: string, label?: string, value?: any): void => {
    const values: MetricValues = {};
    if (category !== undefined) {
        values.event_category = category;
    }
    if (label !== undefined) {
        values.event_label = label;
    }
    if (value !== undefined) {
        values.value = value;
    }
    // @ts-ignore
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
        // @ts-ignore
        window.gtag('event', action, values);
    } else {
        // in case metrics are disabled locally
        console.log('event', action, values);
    }
}