import React, {useEffect, useRef} from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
const LogisticServices = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline.fromTo(".logistic__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".logistic__description", UpStart, InFinish, 0.3)
        ;

    },[]);
    return (
        <section id={'services'} className={'logistic'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="logistic__header">
                            <div className="h2-subtitle">//services</div>
                            <h2 className={'h2 h2-line logistic__h2'}>Logistik <span>dienstleistungen</span></h2>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="logistic__description">
                            Wir bieten erstklassige Logistiklösungen für Unternehmen jeder Größe.
                            Unsere hochwertigen Dienstleistungen umfassen Spedition, internationalen Versand,
                            effiziente Zollabwicklung, moderne Lagerung und Verteilung sowie optimiertes Supply-Chain-Management.
                            Setzen Sie auf unsere Expertise und Qualität für Ihren geschäftlichen Erfolg.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LogisticServices;