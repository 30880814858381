import React, {useEffect, useRef} from 'react';
import ImageBlock from '../UI/ImageBlock';
import workImg from '../../assets/images/work.jpg';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const WorkSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline.fromTo(".work__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".work__content", UpStart, InFinish, 0.3)
        timeline.fromTo(".work__img", UpStart, InFinish, 0.6)
        ;

    },[refTarget]);
    return (
        <section className={'work'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="work__wrap">
                            <div className="work__header">
                                <div className="h2-subtitle">//aboutus</div>
                                <h2 className="h2 h2-line work__h2">
                                    <span>Arbeiten Sie mit uns</span> zu den besten Konditionen auf dem Markt</h2>
                            </div>
                            <div className="work__img d-lg-none d-block">
                                <ImageBlock width={643} height={430} src={workImg}/>
                            </div>
                            <div className="work__content">
                                Entdecken Sie unsere beeindruckenden Leistungskennzahlen, die unseren Erfolg und unsere erstklassigen Dienstleistungen unterstreichen. Wir sind stolz darauf, zahlreiche Unternehmen auf ihrem Weg zum Erfolg unterstützt zu haben. Zögern Sie nicht, uns für ein persönliches Gespräch zu kontaktieren. Wir freuen uns darauf, gemeinsam mit Ihnen die besten Logistiklösungen für Ihr Unternehmen zu entwickeln.
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                        <div className="work__img">
                            <ImageBlock width={643} height={430} src={workImg}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WorkSection;