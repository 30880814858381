import React from 'react';

const Arrow = () => {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.9865 22.4492H16.0175M29.9865 22.4492L25.1846 27.251M29.9865 22.4492L25.1846 17.6473" stroke="#1C3D76" strokeWidth="1.51963" strokeLinecap="round"/>
            <circle cx="22.9108" cy="22.358" r="21.3297" stroke="#1C3D76" strokeWidth="1.51963"/>
            <circle className={'second'} cx="22.5" cy="22.3" r="21" stroke="#FFFFFF" strokeWidth="1.51963"/>
        </svg>
    );
};

export default Arrow;