import React from 'react';

const CourierDelivery = () => {
    return (
        <svg width="62" height="51" viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path fillRule="evenodd" clipRule="evenodd" d="M25.4099 0.57019L33.2361 3.6133L9.87071 12.6988L1.68481 9.51569L24.6912 0.57019C24.9313 0.476603 25.1696 0.476603 25.4099 0.57019ZM49.7801 26.7019C56.3517 26.7019 61.6788 32.0294 61.6788 38.601C61.6788 45.1726 56.3516 50.5 49.7801 50.5C43.2086 50.5 37.8808 45.1726 37.8808 38.601C37.8808 32.0294 43.2085 26.7019 49.7801 26.7019ZM54.2855 33.1747L47.2368 40.2233L44.8315 37.1962C44.2399 36.452 43.1566 36.3283 42.4124 36.9199C41.6679 37.5116 41.5442 38.5947 42.136 39.339L45.676 43.7935C46.2881 44.6704 47.5484 44.7904 48.3111 44.0275L56.7246 35.6139C57.3984 34.9404 57.3984 33.8483 56.7246 33.1747C56.051 32.5012 54.9591 32.5012 54.2855 33.1747ZM41.5932 6.8628C33.8048 9.89153 26.0164 12.9201 18.2276 15.9481L25.0504 18.601L48.4161 9.51569L41.5932 6.8628ZM49.7801 11.1063L26.0421 20.3364V47.8311L36.8386 43.6331C36.2315 42.073 35.8978 40.3762 35.8978 38.601C35.8978 30.9341 42.1131 24.7189 49.7801 24.7189V11.1063ZM24.0588 47.831V20.3364L16.864 17.539V23.3448C16.864 23.6254 16.5362 23.7769 16.3227 23.5949L12.5043 20.3428L8.93324 21.4534C8.88407 21.4689 8.83194 21.4726 8.78107 21.4642C8.7302 21.4558 8.68204 21.4354 8.64051 21.4049C8.59898 21.3743 8.56525 21.3344 8.54206 21.2884C8.51887 21.2423 8.50689 21.1914 8.50708 21.1399V14.2895L0.320801 11.1064V37.9225C0.320801 38.3398 0.564374 38.6957 0.95347 38.8469L24.0588 47.831Z" fill="#6699CC"/>
        </svg>

    );
};

export default CourierDelivery;