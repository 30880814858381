import React from 'react';

const ArrowDown = () => {
    return (
        <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 0.5L3.5 3.5L6.5 0.5" stroke="#243441" strokeLinecap="round"/>
        </svg>
    );
};

export default ArrowDown;