import React from 'react';

const ChatIcon = () => {
    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M80.5002 54.4051C80.5002 44.4777 74.8049 35.618 66.2753 31.3082C66.0105 50.3426 50.5781 65.7747 31.5439 66.0396C35.8535 74.5692 44.7134 80.2646 54.6406 80.2646C59.2952 80.2646 63.8217 79.0249 67.7986 76.6696L80.387 80.1515L76.9051 67.5631C79.2604 63.5859 80.5002 59.0597 80.5002 54.4051Z" fill="#6699CC"></path>
            <path d="M28.7031 42.6082H33.3906V47.2957H28.7031V42.6082ZM26.3593 23.7019H21.6718C21.6718 18.5324 25.8771 14.327 31.0468 14.327C36.2166 14.327 40.4219 18.5324 40.4219 23.7019C40.4219 26.3259 39.3109 28.848 37.3732 30.6203L33.3906 34.2653V37.9206H28.7031V32.2013L34.2085 27.1622C35.1923 26.2616 35.7344 25.0331 35.7344 23.7019C35.7344 21.1171 33.6316 19.0143 31.0468 19.0143C28.4621 19.0143 26.3593 21.1171 26.3593 23.7019ZM61.5937 30.8113C61.5937 13.9675 47.8908 0.264648 31.0468 0.264648C14.2029 0.264648 0.5 13.9675 0.5 30.8113C0.5 36.301 1.96114 41.6458 4.73571 46.3381L0.612433 61.2452L15.5203 57.1224C20.2125 59.897 25.5573 61.3583 31.0468 61.3583C47.8908 61.3583 61.5937 47.6552 61.5937 30.8113Z" fill="#6699CC"></path>
        </svg>
    );
};

export default ChatIcon;