import React, {useEffect, useRef} from 'react';
import CarDelivery from "../icons/CarDelivery";
import CourierDelivery from "../icons/CourierDelivery";
import Shipping from "../icons/Shipping";
import AirDelivery from "../icons/AirDelivery";
import Regrouping from "../icons/Regrouping";
import Storage from "../icons/Storage";
import IconCard from "../UI/IconCard";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const BenefitsSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
            }
        });
        const UpStart = {autoAlpha: 0,opacity:0,duration: 0.3, stagger: 0.3};
        const InFinish = {autoAlpha: 1,opacity:1,duration: 0.3,clearProps: "all", stagger: 0.3};
        timeline.fromTo(".benefits__wrap", UpStart, InFinish, 0.1)
        ;

    },[]);
    return (
        <section className={'benefits'} ref={refTarget}>
            <div className="container container-large">
                <div className="benefits__wrap">
                    <IconCard className={'benefits__item'} img={<CarDelivery/>} title={'Blitzschnelle Lieferung'} description={<>Expresslieferungen für kleine bis große Frachten in ganz Europa!</>}  />
                    <IconCard className={'benefits__item'} img={<CourierDelivery/>} title={'Kurierdienste'} description={<>Transport von Großraumlasten bis 3 Meter in Deutschland, den Niederlanden und Belgien.</>}  />
                    <IconCard className={'benefits__item'} img={<Shipping/>} title={'Gefahrgutversand'} description={<>Transport gefährlicher Güter in Deutschland und Europa auf Anfrage.</>}  />
                    <IconCard className={'benefits__item'} img={<AirDelivery/>} title={'Luftfracht'} description={'Individueller Lufttransport für Ihre speziellen Bedürfnisse auf Anfrage.'}  />
                    <IconCard className={'benefits__item'} img={<Regrouping/>} title={'Umladung'} description={'Effiziente Frachtbündelung für optimierten Transport auf Kundenwunsch.'}  />
                    <IconCard className={'benefits__item'} img={<Storage/>} title={'Lagerung'} description={'Sichere und flexible Lagerung Ihrer Fracht und Güter nach individuellen Bedürfnissen.'}  />
                </div>
            </div>
        </section>
    );
};

export default BenefitsSection;