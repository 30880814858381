import React, {FC} from 'react';
interface IInput {
    className?: string;
    classError?: string;
    name: string;
    placeholder: string;
    message?: string | undefined | false | any;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    value?: string;
}
const Textarea:FC<IInput> = ({name,className,classError,placeholder,onBlur,onChange,value,message}) => {
    return (
        <div className={'textarea'}>
            <label>
                <textarea placeholder={' '} onChange={onChange} onBlur={onBlur} name={name} value={value} />
                <span className="textarea__label">{placeholder}</span>
            </label>
        </div>
    );
};

export default Textarea;