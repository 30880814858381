import React, {FC} from 'react';

interface IDigitBlock{
    className?:string;
    id: number;
    icon: React.ReactNode;
    description: React.ReactNode;
}


const DigitBlock:FC<IDigitBlock> = ({className,id,icon,description}) => {
    return (
        <div className={`digit-block ${className ? className: ''}`}>
            <div className="digit-block__number">
                <div className="digit-block__circle"><span>{id}</span></div>
                <div className="digit-block__line"></div>
                <div className="digit-block__icon">{icon}</div>
            </div>
            <div className="digit-block__content">

                <div className="digit-block__description">
                    {description}
                </div>
            </div>
        </div>
    );
};

export default DigitBlock;