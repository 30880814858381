import React, {useEffect, useRef, useState} from 'react';
import Layout from "../components/sections/Layout";
import MainSection from '../components/sections/MainSection';
import BenefitsSection from "../components/sections/BenefitsSection";
import LogisticServices from "../components/sections/LogisticServices";
import FormSection from "../components/sections/FormSection";
import WorkSection from "../components/sections/WorkSection";
import IndicatorSection from "../components/sections/IndicatorSection";
import TopFiveSection from "../components/sections/TopFiveSection";
import Preloader from "../components/Preloader";
import SliderSection from "../components/sections/SliderSection";
import MapSection from "../components/sections/MapSection";
import EcologySection from "../components/sections/EcologySection";


import mainImg from '../assets/images/main.png';
import ecoImg from "../assets/images/ecology.jpg"
import workImg from '../assets/images/work.jpg';

import img1 from '../assets/images/slider/1.jpg'
import img2 from '../assets/images/slider/2.jpg'
import img3 from '../assets/images/slider/3.jpg'
import {gsap} from "gsap";
import usePreloader from '../hooks/usePreloader';

const Home = () => {
    const images = [
        mainImg,
        workImg,
        ecoImg,
        img1,
        img2,
        img3
    ];

    const isLoading = usePreloader(images);
    //if(isLoading) return <Preloader/>
    useEffect(() => {
        if(!isLoading){
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        }

    }, [isLoading]);
    return (
        <>
            {isLoading && <Preloader/>}
                <Layout>
                    <div className={'wrapper'} >
                        <MainSection/>
                        <BenefitsSection/>
                        <LogisticServices/>
                        <FormSection/>
                        <WorkSection/>
                        <IndicatorSection/>
                        <TopFiveSection/>
                        <SliderSection/>
                        <MapSection/>
                        <EcologySection/>
                    </div>
                </Layout>
        </>

    );
};

export default Home;