/*import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';*/
//import Marker from './icons/Marker';
import {FC} from "react";

/*import { Icon } from "leaflet";*/
interface MapProps {
    position: any;
    zoom: number;
}
/*const markers = [
    {
        geocode: [51.361813216545016, 6.572742462158204],
        popUp: "Hello, I'm a popup1"
    },
    {
        geocode: [51.30550629700758, 6.551971435546876],
        popUp: "Hello, I'm a popup2"
    },
    {
        geocode: [51.32031367286625, 6.624755859375001],
        popUp: "Hello, I'm a popup3"
    }
];

const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png",
    iconSize: [38, 38]
});*/

const Map:FC<MapProps> = ({ position, zoom } ) => {
    /*boundsOptions={zoom}*/


    //
    return (
        <iframe className={'map-container'}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4985.053831033616!2d6.568034244880534!3d51.33822081555991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8baac4d9a66cf%3A0x12b5345b98db6b60!2sLeyentalstra%C3%9Fe%2061%2C%2047799%20Krefeld%2C%20Deutschland!5e0!3m2!1sde!2sua!4v1682431711558!5m2!1sde!2sua"
            width="600" height="450" style={{border:'none'}} allowFullScreen loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
/*            <MapContainer className={'map-container'} center={[51.33157695334825, 6.56209945678711]} zoom={12} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {markers.map((marker) => (
                    <>
                        {/!* @ts-ignore *!/}
                        <Marker position={marker.geocode} icon={customIcon}>
                            <Popup>{marker.popUp}</Popup>
                        </Marker>
                    </>

                ))}
            </MapContainer>*/
    );
}
export default Map;