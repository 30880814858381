import React, {FC} from 'react';
import {Link} from "react-router-dom";

interface IButton {
    type?: 'link' | 'button' | "htmlLink";
    name: string;
    className?: string;
    onClick?: ()=>void
    href?:string;
}

const Button:FC<IButton> = ({type="link",name,className, onClick, href}) => {
    return (
        type === 'htmlLink' ? <a onClick={onClick} className={`btn ${className? className : ''}`} target={"_blank"} href={href as string}><span>{name}</span></a>
            :
            type === 'button' ?
                <button type={'submit'} onClick={onClick} className={`btn ${className? className : ''}`}>
                    <span> {name}</span>
                </button>
                :
                <Link onClick={onClick} className={`btn ${className? className : ''}`} to={href as string}>
                    <span>{name}</span>
                </Link>
    );
};

export default Button;