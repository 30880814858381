import React, {FC} from 'react';

interface IImageBlock{
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    type?: "left" | "right";
}

const ImageBlock:FC<IImageBlock> = ({src,alt,width='auto',height='auto',type="right"}) => {
    return (
        <div className={`image-block ${type === "left" ? "image-block-left": ""}`} style={{width:width,height:height}}>
            <div className="image-block__img">
                <img src={src} alt={alt ? alt : ""}/>
            </div>

        </div>
    );
};

export default ImageBlock;