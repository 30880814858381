import React, {useState} from 'react';
import Button from "./UI/Button";
import {useDispatchEx} from "../hooks/redux";
import { changeModalCookies } from '../redux/slices/appSlice';
import Cookies from "js-cookie";

const CookiesInfo = () => {
    const dispatch = useDispatchEx();
    const [open, setOpen] = useState(true)
    const confirmCookies = ()=>{
        //localStorage.setItem('cookiesInfo',"true");
        Cookies.set('cookiesInfo', 'true', { expires: 31 })
        setOpen(false)
    }
    return (
        <div className={`cookies ${open ? "active" : ""}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="cookies__wrap">
                            <div className="cookies__header">Wir nutzen cookies!</div>
                            <div className="cookies__content">
                                Wir verwenden Cookies, um Ihnen das bestmögliche Nutzererlebnis auf unserer Website zu bieten. Einige Cookies sind für die einwandfreie Funktion der Website notwendig, während andere uns helfen, die Nutzung der Website zu analysieren und zu optimieren. Sie können selbst entscheiden, welche
                                 <a onClick={()=>dispatch(changeModalCookies(true))}>Cookies Sie zulassen möchten.</a>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="cookies__buttons">

                            <Button onClick={confirmCookies} className={'btn-small-white cookies__btn'} name={'Alle ablehnen'}/>
                            <Button onClick={confirmCookies} className={'btn-small-white cookies__btn'} name={'Alle akzeptieren'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesInfo;