import React from 'react';

const Run = () => {
    return (
        <svg width="50" height="45" viewBox="0 0 50 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2258 4.66479H5.03443C4.24991 4.66479 3.61508 5.29962 3.61508 6.08415C3.61508 6.86867 4.24991 7.5035 5.03443 7.5035H12.2258C13.0104 7.5035 13.6452 6.86867 13.6452 6.08415C13.6452 5.29962 13.0104 4.66479 12.2258 4.66479ZM12.2258 16.0196H5.03443C4.24991 16.0196 3.61508 16.6545 3.61508 17.439C3.61508 18.2235 4.24991 18.8583 5.03443 18.8583H12.2258C13.0104 18.8583 13.6452 18.2235 13.6452 17.439C13.6452 16.6545 13.0104 16.0196 12.2258 16.0196ZM9.38712 10.3422H2.19572C1.4112 10.3422 0.776367 10.977 0.776367 11.7616C0.776367 12.5461 1.4112 13.1809 2.19572 13.1809H9.38712C10.1716 13.1809 10.8065 12.5461 10.8065 11.7616C10.8065 10.977 10.1716 10.3422 9.38712 10.3422ZM46.385 17.439H37.7742V11.7616C37.7742 9.26861 34.7246 7.95599 32.9276 9.75536L21.5736 21.1093C20.4647 22.2182 20.4647 24.0146 21.5736 25.1235L28.0827 31.6325L20.1543 39.561C19.0454 40.6699 19.0454 42.4662 20.1543 43.5751C21.2631 44.6839 23.0595 44.684 24.1684 43.5751L34.1039 33.6396C35.2128 32.5307 35.2128 30.7344 34.1039 29.6255L27.5948 23.1164L32.0968 18.6144V20.2777C32.0968 21.8453 33.3679 23.1164 34.9355 23.1164H46.385C47.9526 23.1164 49.2237 21.8453 49.2237 20.2777C49.2237 18.7101 47.9526 17.439 46.385 17.439ZM27.2842 3.01266C26.1559 2.26277 24.659 2.41114 23.7025 3.3675L15.8961 11.174C14.7872 12.2828 14.7872 14.0792 15.8961 15.1881C17.005 16.297 18.8014 16.2969 19.9103 15.188L26.0714 9.02685L28.1469 10.5219L30.92 7.74886C31.3838 7.28502 31.9309 6.92497 32.5292 6.64385L27.2842 3.01266Z" fill="#1C3D76"/>
            <path d="M19.5663 27.1305C18.847 26.4113 18.3669 25.5261 18.1138 24.5688L3.12171 39.5609C2.01282 40.6698 2.01282 42.4662 3.12171 43.575C4.23051 44.6838 6.02694 44.6839 7.13583 43.575L21.5733 29.1376L19.5663 27.1305Z" fill="#1C3D76"/>
            <path d="M39.1936 8.92287C41.5453 8.92287 43.4517 7.01647 43.4517 4.6648C43.4517 2.31314 41.5453 0.406738 39.1936 0.406738C36.8419 0.406738 34.9355 2.31314 34.9355 4.6648C34.9355 7.01647 36.8419 8.92287 39.1936 8.92287Z" fill="#1C3D76"/>
        </svg>
    );
};

export default Run;