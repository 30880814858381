import React from 'react';
import Lottie from "lottie-react";
import animation from "../../assets/effects/Loading.json";

const Logo = () => {
    return (
        <>
                <div className="logo">
                        <div className="logo__img-wrap">
                                <div className="logo__wrapper">
                                        <Lottie className={'logo__img'} animationData={animation} loop={true} />
                                </div>

                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 796 227">
                                <path fill="#000066"
                                      d="M 656.62,151.98 L 657.15,148.52 A 1.11,0.96 4.0 0 0 656.04,147.41 L 650.19,147.37 A 0.60,0.54 0.5 0 1 649.59,146.82 L 649.70,142.54 A 0.62,0.49 -0.1 0 1 650.31,142.06 L 662.74,141.83 A 0.99,0.98 -0.6 0 1 663.75,142.81 L 663.75,153.74 A 3.03,3.02 -17.0 0 1 662.41,156.25 Q 657.98,159.21 652.97,159.99 Q 647.79,160.81 643.16,159.09 Q 634.32,155.82 633.24,145.00 Q 632.21,134.74 639.38,129.21 Q 642.63,126.70 646.76,126.18 Q 650.85,125.67 654.99,126.80 Q 661.22,128.52 663.00,134.77 A 0.98,0.93 -12.9 0 1 662.21,135.96 L 657.74,136.76 A 1.07,1.02 -20.9 0 1 656.66,136.31 Q 655.37,134.27 653.82,133.08 Q 652.26,131.87 649.89,131.78 Q 642.01,131.47 640.00,139.75 Q 639.51,141.75 640.11,145.73 Q 641.31,153.85 648.89,154.46 Q 650.55,154.59 652.50,153.92 Q 654.26,153.32 655.98,152.62 A 1.04,0.65 -13.0 0 0 656.62,151.98 Z"></path>
                                <path fill="#000066"
                                      d="M 197.12,152.76 L 195.26,158.20 A 2.29,2.04 -79.5 0 1 193.26,159.75 L 188.73,159.75 A 0.94,0.93 -79.1 0 1 187.87,158.46 L 200.01,127.41 A 0.94,0.62 -76.0 0 1 200.68,126.80 L 206.30,126.65 A 2.07,1.67 76.6 0 1 208.02,127.98 L 220.14,158.69 A 0.77,0.77 79.2 0 1 219.42,159.75 L 214.48,159.75 A 1.95,1.82 79.6 0 1 212.73,158.45 L 210.63,152.52 A 0.73,0.72 79.1 0 0 209.94,152.04 L 197.81,152.27 A 0.75,0.71 8.2 0 0 197.12,152.76 ZM 207.48,146.62 A 0.61,0.57 -9.7 0 0 208.06,145.86 L 204.71,136.38 Q 203.90,134.08 203.08,136.34 L 199.74,145.52 A 1.03,0.92 8.1 0 0 200.77,146.75 L 207.48,146.62 Z"></path>
                                <path fill="#000066"
                                      d="M 252.44,157.30 Q 251.57,157.38 251.05,158.13 Q 250.72,158.62 250.37,158.81 Q 245.53,161.44 241.51,159.16 Q 238.26,157.31 236.66,152.94 Q 234.86,148.03 236.38,143.04 Q 237.86,138.18 241.66,136.13 Q 246.41,133.56 251.59,137.44 Q 252.75,138.31 252.75,136.86 Q 252.75,132.50 252.51,128.52 Q 252.36,126.11 254.77,126.50 Q 255.92,126.69 257.06,126.43 Q 259.17,125.94 259.15,128.25 Q 259.00,143.25 259.00,158.57 A 1.15,1.15 -1.9 0 1 257.92,159.72 Q 256.47,159.80 254.52,159.56 Q 252.90,159.35 252.90,157.81 A 0.51,0.42 87.9 0 0 252.44,157.30 ZM 247.5968,155.2500 A 7.69,5.35 89.8 0 0 252.9200,147.5413 A 7.69,5.35 89.8 0 0 247.5432,139.8700 A 7.69,5.35 89.8 0 0 242.2200,147.5787 A 7.69,5.35 89.8 0 0 247.5968,155.2500 Z"></path>
                                <path fill="#000066"
                                      d="M 285.06,146.57 L 284.94,158.94 A 0.64,0.63 83.8 0 1 284.45,159.56 Q 281.79,160.17 279.17,159.45 A 0.66,0.66 -82.6 0 1 278.68,158.81 L 278.55,127.56 A 1.07,1.05 -0.3 0 1 279.61,126.51 Q 288.25,126.40 296.25,126.75 Q 300.64,126.94 303.01,129.57 Q 305.13,131.93 305.19,135.89 Q 305.32,142.97 297.48,144.89 Q 297.01,145.00 297.38,145.33 Q 303.78,151.08 307.24,158.98 A 0.40,0.40 73.0 0 1 306.95,159.53 Q 303.75,160.12 300.55,159.53 A 1.26,1.16 75.3 0 1 299.77,159.03 Q 296.09,153.90 292.69,148.56 Q 290.45,145.05 285.55,145.96 A 0.62,0.60 85.0 0 0 285.06,146.57 ZM 297.95,138.27 Q 299.50,135.07 297.34,133.33 Q 296.09,132.32 293.29,132.27 Q 289.61,132.22 285.93,132.19 A 0.63,0.62 -83.6 0 0 285.32,132.68 Q 284.46,136.38 285.32,140.07 A 0.67,0.63 83.1 0 0 285.94,140.59 L 294.73,140.45 Q 296.92,140.42 297.95,138.27 Z"></path>
                                <path fill="#000066"
                                      d="M 344.70,132.47 Q 340.84,131.76 338.50,132.25 Q 334.38,133.11 335.52,127.74 A 1.30,1.29 -85.1 0 1 336.73,126.71 Q 348.30,126.18 360.81,126.67 A 0.65,0.62 -3.8 0 1 361.42,127.18 Q 361.55,127.86 361.54,128.56 Q 361.53,129.13 361.57,129.93 Q 361.72,132.88 358.25,132.25 Q 357.23,132.06 352.31,132.29 A 0.52,0.52 -1.1 0 0 351.81,132.81 L 351.69,158.93 A 0.67,0.63 83.3 0 1 351.21,159.57 Q 350.11,159.84 348.97,159.82 Q 348.66,159.81 347.04,159.87 Q 344.73,159.94 344.89,157.54 Q 344.99,156.05 344.99,155.94 Q 344.89,144.50 345.17,133.06 A 0.58,0.57 -83.7 0 0 344.70,132.47 Z"></path>
                                <rect fill="#000066" x="-3.12" y="-3.01" transform="translate(559.87,129.50) rotate(0.9)" width="6.24"
                                      height="6.02" rx="1.07"></rect>
                                <path fill="#000066"
                                      d="M 717.98,137.66 Q 725.91,132.51 731.00,138.04 Q 733.98,141.30 734.40,146.13 Q 734.82,151.01 732.40,155.17 Q 730.13,159.06 726.53,160.01 Q 722.28,161.13 718.28,157.66 Q 717.03,156.58 717.07,158.23 Q 717.11,160.00 715.30,159.92 Q 714.13,159.86 713.21,159.96 Q 711.01,160.20 711.06,158.06 Q 711.44,143.06 711.17,127.37 A 0.86,0.86 89.7 0 1 712.03,126.50 L 716.65,126.50 A 0.87,0.86 89.7 0 1 717.51,127.36 L 717.55,137.43 Q 717.55,137.94 717.98,137.66 ZM 722.8413,155.2383 A 7.70,5.30 88.8 0 0 727.9788,147.4290 A 7.70,5.30 88.8 0 0 722.5187,139.8417 A 7.70,5.30 88.8 0 0 717.3812,147.6510 A 7.70,5.30 88.8 0 0 722.8413,155.2383 Z"></path>
                                <path fill="#000066"
                                      d="M 758.94,145.51 Q 753.07,144.92 747.19,145.39 A 0.59,0.56 -2.3 0 0 746.66,145.94 L 746.51,158.82 A 0.78,0.64 -89.6 0 1 745.87,159.59 L 740.48,159.61 A 0.86,0.73 90.0 0 1 739.75,158.75 L 739.75,127.75 A 1.23,1.20 -90.0 0 1 740.95,126.52 L 745.30,126.53 A 1.34,1.23 89.8 0 1 746.53,127.86 L 746.66,139.06 A 0.53,0.50 0.0 0 0 747.19,139.56 L 758.94,139.44 A 0.51,0.51 0.0 0 0 759.45,138.93 L 759.49,127.82 A 1.07,1.04 85.5 0 1 760.35,126.78 Q 763.79,126.19 765.73,126.87 A 0.77,0.66 -81.5 0 1 766.17,127.57 L 766.28,158.23 A 1.43,1.28 -89.8 0 1 764.99,159.66 L 760.18,159.63 A 0.58,0.51 89.4 0 1 759.67,159.06 L 759.43,146.05 A 0.55,0.54 2.9 0 0 758.94,145.51 Z"></path>
                                <path fill="#000066"
                                      d="M 369.79,137.82 Q 372.49,133.87 377.17,135.62 A 1.30,1.30 -79.3 0 1 378.02,136.87 Q 377.97,139.97 376.58,140.85 Q 376.16,141.11 373.54,140.79 Q 371.00,140.49 370.25,143.25 Q 369.01,147.83 369.42,158.95 A 0.60,0.47 88.9 0 1 368.95,159.57 L 364.38,159.54 A 1.28,1.14 -89.8 0 1 363.25,158.26 L 363.31,136.56 A 0.68,0.65 82.8 0 1 363.79,135.91 Q 364.76,135.65 365.77,135.68 Q 365.89,135.69 367.23,135.63 Q 368.85,135.55 369.03,137.62 Q 369.14,138.78 369.79,137.82 Z"></path>
                                <path fill="#000066"
                                      d="M 382.35,138.43 Q 383.78,136.67 385.92,135.94 Q 391.22,134.12 397.19,136.31 Q 399.64,137.22 400.50,140.25 Q 401.00,142.02 400.69,149.25 Q 400.48,154.09 401.63,158.77 A 0.67,0.61 70.1 0 1 401.33,159.51 Q 400.62,159.89 399.77,159.86 Q 399.09,159.83 398.23,159.87 Q 396.48,159.94 395.15,158.13 A 0.74,0.73 -37.2 0 0 394.09,157.99 Q 391.02,160.46 386.76,160.16 Q 383.07,159.90 381.23,157.34 Q 379.53,154.98 380.05,151.87 Q 380.80,147.34 386.24,146.21 Q 389.75,145.48 393.25,144.73 Q 395.27,144.30 393.98,141.45 A 2.34,2.31 85.4 0 0 392.49,140.17 Q 388.51,139.04 386.45,142.25 A 1.48,0.91 -71.4 0 1 385.72,142.86 Q 384.93,143.07 384.13,142.77 Q 383.61,142.57 383.19,142.57 Q 378.98,142.58 382.35,138.43 ZM 386.45,151.41 Q 386.09,152.58 386.45,153.62 Q 386.78,154.59 387.82,155.16 Q 390.20,156.49 392.65,154.64 Q 394.92,152.93 394.36,149.03 A 0.65,0.59 77.8 0 0 393.59,148.48 L 387.32,150.45 A 1.50,1.23 -87.0 0 0 386.45,151.41 Z"></path>
                                <path fill="#000066"
                                      d="M 413.69,138.10 Q 419.29,133.21 425.44,136.31 Q 427.86,137.53 428.51,140.35 Q 428.69,141.12 428.91,145.16 Q 429.30,152.05 428.73,158.95 A 0.67,0.65 86.6 0 1 428.21,159.55 Q 425.55,160.16 423.33,159.58 A 1.19,1.17 -79.4 0 1 422.47,158.31 Q 423.38,149.96 422.03,142.18 Q 421.67,140.07 419.52,140.00 Q 416.22,139.90 414.70,142.07 Q 413.77,143.40 413.63,146.64 Q 413.39,152.34 413.52,158.68 A 1.03,1.01 89.4 0 1 412.51,159.73 L 407.90,159.71 A 0.74,0.72 -89.6 0 1 407.19,158.97 L 407.48,136.51 A 0.61,0.52 89.5 0 1 407.99,135.90 L 412.31,135.85 A 0.71,0.62 82.9 0 1 412.95,136.42 L 413.25,137.94 A 0.28,0.27 63.8 0 0 413.69,138.10 Z"></path>
                                <path fill="#000066"
                                      d="M 448.46,151.64 Q 445.83,150.72 441.50,149.75 Q 439.16,149.22 437.11,147.74 Q 435.04,146.23 434.51,143.96 Q 433.71,140.59 435.99,138.16 Q 437.72,136.32 440.85,135.60 Q 445.24,134.60 449.74,136.04 Q 452.65,136.97 454.32,140.17 A 0.88,0.83 69.1 0 1 453.81,141.41 L 449.80,142.39 A 0.90,0.64 59.5 0 1 449.01,142.04 Q 446.26,138.57 441.67,140.13 Q 438.74,141.12 441.00,143.00 Q 442.14,143.95 449.66,145.69 Q 453.93,146.67 455.08,150.11 Q 456.12,153.21 454.24,156.15 Q 452.68,158.59 449.86,159.50 Q 447.70,160.20 444.62,160.32 Q 437.67,160.59 433.72,154.29 A 0.91,0.85 67.3 0 1 434.22,152.94 L 438.48,152.04 A 1.31,1.19 67.7 0 1 439.80,152.67 Q 441.94,156.24 446.27,155.68 Q 449.59,155.26 449.05,152.30 A 0.90,0.84 5.1 0 0 448.46,151.64 Z"></path>
                                <path fill="#000066"
                                      d="M 467.01,143.06 Q 469.07,144.13 472.67,144.87 Q 475.84,145.53 478.69,147.06 Q 480.74,148.17 481.16,150.67 Q 481.50,152.68 480.69,154.76 Q 479.19,158.62 474.76,159.79 Q 464.67,162.44 459.51,154.17 A 0.54,0.53 56.3 0 1 459.65,153.45 Q 461.83,151.93 464.57,152.14 A 1.06,0.94 72.7 0 1 465.33,152.60 Q 468.25,156.82 473.14,155.18 Q 474.47,154.74 474.91,153.77 Q 475.46,152.55 474.34,151.72 Q 473.80,151.32 472.56,151.08 Q 468.57,150.32 464.90,148.70 Q 461.60,147.25 460.60,145.15 Q 459.21,142.24 461.37,138.85 Q 463.10,136.13 466.31,135.55 Q 470.66,134.75 474.92,135.89 Q 478.70,136.90 480.20,140.37 A 0.85,0.73 72.9 0 1 479.70,141.51 L 475.25,142.27 A 1.03,0.65 59.9 0 1 474.45,141.85 Q 471.69,138.44 467.17,140.19 Q 463.91,141.45 467.01,143.06 Z"></path>
                                <path fill="#000066"
                                      d="M 490.52,136.92 Q 493.33,135.03 496.70,135.17 Q 501.35,135.36 504.36,138.72 Q 507.47,142.18 507.50,147.94 A 1.83,1.76 0.5 0 1 505.63,149.71 L 493.22,149.50 A 1.39,1.35 79.8 0 0 491.95,151.38 Q 493.75,156.20 498.45,155.30 A 1.43,1.41 13.7 0 0 499.27,154.81 L 501.01,152.70 A 1.00,0.72 -61.0 0 1 501.81,152.32 L 505.84,153.04 A 1.18,1.11 -66.8 0 1 506.50,154.85 Q 502.08,161.22 495.18,160.23 Q 490.98,159.63 488.50,156.93 Q 485.88,154.09 485.50,149.50 Q 485.14,145.13 486.66,141.62 Q 487.95,138.65 490.52,136.92 ZM 492.30,143.12 Q 490.76,145.78 493.62,145.70 L 500.94,145.50 A 0.51,0.48 85.6 0 0 501.40,144.94 Q 501.15,142.52 499.52,141.09 Q 497.75,139.54 495.68,140.14 Q 493.68,140.73 492.30,143.12 Z"></path>
                                <path fill="#000066"
                                      d="M 518.94,138.29 Q 519.17,138.29 519.31,138.06 Q 520.39,136.33 522.05,135.65 Q 523.14,135.21 525.48,135.49 Q 529.59,136.00 533.75,135.78 A 0.74,0.73 75.9 0 1 534.46,136.21 Q 537.57,142.92 539.61,150.03 A 1.25,0.80 85.7 0 0 540.17,150.78 Q 540.74,151.02 541.00,150.25 Q 543.35,143.36 544.83,139.58 Q 544.87,139.49 545.38,137.74 Q 546.14,135.12 549.25,135.65 Q 549.75,135.73 550.23,135.60 Q 553.14,134.80 551.93,137.86 Q 547.97,147.81 543.76,158.36 A 2.17,2.17 -87.3 0 1 542.38,159.62 Q 537.63,161.05 536.32,156.96 Q 535.72,155.07 535.67,154.95 Q 532.27,146.43 528.91,137.90 Q 528.71,137.40 528.38,137.27 Q 527.78,137.03 527.58,138.02 Q 527.22,139.79 526.09,141.13 A 0.71,0.64 31.2 0 1 525.29,141.28 Q 522.66,140.14 520.93,141.69 Q 519.81,142.70 519.44,145.19 Q 518.39,152.34 519.00,157.00 Q 519.39,160.00 516.95,159.85 Q 515.63,159.76 515.54,159.76 Q 514.36,159.78 513.34,159.71 A 0.72,0.71 -87.9 0 1 512.68,158.99 Q 512.79,148.92 512.75,139.36 Q 512.75,139.25 512.63,137.65 Q 512.42,135.03 515.03,135.62 Q 515.49,135.73 515.98,135.63 Q 518.58,135.09 518.58,137.80 A 0.49,0.36 90.0 0 0 518.94,138.29 Z"></path>
                                <path fill="#000066"
                                      d="M 572.77,137.57 Q 578.47,132.88 586.06,136.69 Q 589.31,138.32 589.88,142.19 A 0.56,0.54 80.6 0 1 589.45,142.81 L 585.30,143.62 A 0.86,0.82 -23.7 0 1 584.47,143.32 L 582.70,140.99 A 1.57,0.89 66.7 0 0 581.96,140.43 Q 575.90,138.85 574.85,144.97 Q 574.25,148.47 575.40,151.82 Q 576.28,154.38 579.03,154.79 Q 583.40,155.43 584.56,151.42 A 0.77,0.69 -82.9 0 1 585.18,150.88 Q 587.44,150.70 589.83,151.53 A 0.80,0.79 -71.9 0 1 590.32,152.51 Q 587.93,160.40 579.75,160.13 Q 569.77,159.80 568.50,149.75 Q 568.04,146.14 569.06,142.98 Q 570.09,139.77 572.77,137.57 Z"></path>
                                <path fill="#000066"
                                      d="M 600.30,150.32 Q 600.30,154.92 604.75,155.50 Q 607.62,155.87 609.10,153.01 A 0.96,0.91 -79.2 0 1 609.81,152.50 Q 612.29,152.20 613.51,152.59 Q 616.22,153.48 614.38,155.66 Q 609.49,161.46 602.50,160.01 Q 595.84,158.64 594.17,152.02 Q 592.28,144.50 596.06,139.31 Q 598.97,135.33 603.82,135.13 Q 608.31,134.95 611.51,137.63 Q 615.63,141.08 615.77,147.98 A 1.70,1.70 1.5 0 1 613.94,149.71 Q 607.25,149.17 600.81,149.77 A 0.55,0.55 -2.2 0 0 600.30,150.32 ZM 600.85,142.72 Q 598.70,145.58 602.15,145.64 L 608.55,145.74 A 1.11,1.09 81.2 0 0 609.59,144.29 Q 609.14,142.88 608.41,141.78 Q 607.58,140.52 606.28,140.19 Q 603.32,139.44 600.85,142.72 Z"></path>
                                <path fill="#000066"
                                      d="M 676.02,138.24 Q 676.83,137.85 677.47,137.23 Q 678.30,136.43 678.86,136.17 Q 685.00,133.40 689.43,138.38 A 0.45,0.44 -44.2 0 0 690.09,138.38 Q 695.12,133.13 701.44,136.31 Q 703.02,137.11 703.91,139.05 Q 704.49,140.32 704.58,142.54 Q 704.90,150.11 704.73,158.41 A 1.11,1.10 85.9 0 1 703.81,159.48 Q 700.82,160.00 699.16,159.49 A 0.73,0.69 -81.9 0 1 698.67,158.80 L 698.51,143.60 Q 698.47,140.31 695.31,139.97 Q 692.89,139.70 691.56,141.75 Q 690.78,142.96 690.58,145.41 Q 690.06,151.79 690.28,158.83 A 0.90,0.90 89.4 0 1 689.38,159.75 L 685.64,159.75 A 1.73,1.73 -87.4 0 1 683.92,157.86 Q 684.70,149.36 683.80,142.16 Q 683.55,140.13 681.52,140.00 Q 678.98,139.84 677.54,141.37 Q 676.53,142.44 676.30,144.94 Q 675.73,151.24 676.06,158.59 A 0.92,0.83 88.7 0 1 675.24,159.55 L 670.29,159.56 A 0.62,0.48 90.0 0 1 669.81,158.94 L 669.75,136.76 A 0.96,0.94 89.7 0 1 670.68,135.80 L 674.25,135.74 A 1.06,0.97 -17.4 0 1 675.09,136.13 Q 675.67,136.90 675.62,137.94 A 0.30,0.28 79.1 0 0 676.02,138.24 Z"></path>
                                <rect fill="#000066" x="556.75" y="135.65" width="6.24" height="24.08" rx="0.83"></rect>
                                <rect fill="#000066" x="-3.18" y="-3.24" transform="translate(227.45,156.50) rotate(-0.6)" width="6.36"
                                      height="6.48" rx="1.02"></rect>
                                <rect fill="#000066" x="-3.13" y="-3.24" transform="translate(268.64,156.50) rotate(-0.6)" width="6.26"
                                      height="6.48" rx="0.95"></rect>
                                <rect fill="#000066" x="-3.23" y="-3.23" transform="translate(314.98,156.51) rotate(-0.9)" width="6.46"
                                      height="6.46" rx="1.46"></rect>
                                <path fill="#b4b8b6"
                                      d="M 433.90,192.15 Q 434.53,192.10 434.48,190.45 A 1.44,1.44 82.1 0 1 435.57,189.00 Q 437.98,188.39 437.76,191.00 Q 437.62,192.60 436.61,194.05 A 2.01,1.89 40.6 0 0 436.88,196.52 L 439.17,198.87 A 0.86,0.83 -59.1 0 1 439.37,199.73 Q 438.34,202.85 435.02,199.35 A 1.33,1.33 -43.5 0 0 433.15,199.30 Q 432.20,200.20 430.47,200.95 Q 428.96,201.59 427.44,201.69 Q 422.54,202.01 420.27,198.34 Q 418.36,195.24 419.40,191.93 Q 420.45,188.59 424.18,186.62 A 0.71,0.70 55.7 0 0 424.38,185.54 Q 422.82,183.71 422.28,181.63 Q 421.71,179.42 422.85,177.51 Q 424.21,175.23 426.86,174.70 Q 429.26,174.23 431.34,175.43 Q 434.26,177.11 433.81,180.71 Q 433.59,182.52 432.50,183.93 Q 431.38,185.39 429.76,186.21 A 0.59,0.59 57.1 0 0 429.57,187.11 Q 431.44,189.41 432.94,191.43 Q 433.49,192.18 433.90,192.15 ZM 425.34,180.42 Q 425.51,182.44 427.17,183.87 A 0.83,0.81 53.8 0 0 428.03,184.01 Q 430.68,182.89 430.78,180.19 Q 430.84,178.71 429.72,177.81 Q 428.24,176.62 426.73,177.52 Q 425.17,178.47 425.34,180.42 ZM 423.05,191.10 Q 421.22,193.89 423.21,196.58 Q 426.41,200.90 430.96,196.97 A 1.77,1.70 52.3 0 0 431.21,194.67 L 427.56,189.43 A 1.35,1.31 59.5 0 0 425.89,188.97 Q 423.76,190.01 423.05,191.10 Z"></path>
                                <path fill="#b4b8b6"
                                      d="M 465.49,188.35 Q 465.64,190.04 466.70,188.71 Q 469.02,185.79 471.60,183.36 Q 472.88,182.15 475.23,182.20 Q 476.61,182.22 475.64,183.25 L 470.02,189.18 A 0.83,0.70 51.7 0 0 469.99,190.13 L 476.54,200.55 A 0.42,0.40 -18.3 0 1 476.22,201.16 Q 473.64,201.47 472.67,199.88 Q 470.35,196.06 467.79,192.41 A 0.77,0.69 63.7 0 0 467.01,192.10 Q 465.92,192.41 465.69,193.44 Q 465.09,196.09 465.18,200.43 A 0.74,0.70 80.1 0 1 464.72,201.13 Q 462.22,202.01 462.31,199.06 Q 462.49,193.61 462.32,176.07 A 0.91,0.83 75.1 0 1 462.72,175.30 Q 465.60,173.53 465.34,176.93 Q 465.23,178.46 465.23,178.57 Q 465.10,183.83 465.49,188.35 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 618.92,183.44 L 618.59,176.05 A 0.82,0.75 76.3 0 1 619.00,175.31 Q 620.18,174.73 621.46,174.95 A 0.63,0.55 -85.7 0 1 621.92,175.56 L 621.92,200.43 A 0.79,0.59 83.9 0 1 621.49,201.15 Q 620.54,201.44 619.14,200.66 A 1.16,1.15 -39.8 0 0 618.20,200.58 Q 609.90,203.50 607.06,196.44 Q 605.40,192.30 606.68,188.04 Q 607.84,184.17 610.78,182.63 Q 614.39,180.75 618.53,183.64 Q 618.95,183.93 618.92,183.44 ZM 614.3926,199.2086 A 7.43,4.91 88.9 0 0 619.1591,191.6857 A 7.43,4.91 88.9 0 0 614.1074,184.3514 A 7.43,4.91 88.9 0 0 609.3409,191.8743 A 7.43,4.91 88.9 0 0 614.3926,199.2086 Z"></path>
                                <rect fill="#aaabab" fillOpacity="0.996" x="-1.62" y="-1.75"
                                      transform="translate(541.14,176.74) rotate(-1.3)" width="3.24" height="3.50" rx="0.55"></rect>
                                <rect fill="#b4b8b6" x="-1.57" y="-1.85" transform="translate(637.90,176.82) rotate(-2.2)" width="3.14"
                                      height="3.70" rx="0.89"></rect>
                                <path fill="#aaabab" fillOpacity="0.996"
                                      d="M 743.06,182.00 L 744.93,182.25 A 1.04,0.81 -5.5 0 1 745.70,182.74 Q 746.44,184.30 743.54,184.86 A 1.25,1.18 -6.2 0 0 742.53,186.04 L 742.72,197.32 A 1.04,0.95 7.6 0 0 743.50,198.25 Q 746.26,198.95 745.69,200.78 A 0.95,0.85 0.9 0 1 745.01,201.37 Q 740.64,202.32 739.69,199.00 Q 739.44,198.11 739.26,194.12 Q 739.08,190.22 739.55,186.09 A 1.20,1.19 13.1 0 0 738.77,184.84 Q 734.91,183.48 738.02,182.41 A 1.85,1.83 80.1 0 0 739.27,180.62 Q 739.19,176.47 741.95,175.88 A 0.45,0.42 84.1 0 1 742.45,176.32 L 742.55,181.44 A 0.58,0.58 2.9 0 0 743.06,182.00 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 329.58,192.97 Q 328.50,196.35 331.44,198.06 Q 336.15,200.80 339.25,196.00 Q 340.31,194.36 341.66,195.64 A 0.97,0.89 -64.1 0 1 341.92,196.50 Q 341.21,200.13 337.50,201.25 Q 333.00,202.62 329.81,200.46 Q 327.05,198.58 326.18,194.56 Q 325.26,190.35 327.00,186.50 Q 328.81,182.47 332.84,181.92 Q 336.56,181.40 339.23,183.65 Q 342.29,186.21 342.55,191.50 A 1.15,1.11 89.0 0 1 341.43,192.71 L 330.24,192.54 A 0.70,0.62 8.4 0 0 329.58,192.97 ZM 329.73,187.54 Q 328.39,189.82 331.13,189.88 L 338.27,190.04 A 0.71,0.68 1.2 0 0 338.99,189.38 Q 339.15,185.68 335.73,184.83 Q 331.88,183.88 329.73,187.54 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 366.28,197.07 Q 367.43,195.06 365.27,194.26 Q 362.17,193.10 359.05,192.10 Q 356.45,191.27 355.63,189.09 Q 354.87,187.08 355.96,185.01 Q 357.83,181.46 363.06,181.94 Q 367.46,182.34 368.61,185.78 A 1.20,1.16 50.1 0 1 368.54,186.70 Q 367.51,188.66 365.94,186.81 Q 362.52,182.79 358.70,185.74 A 1.40,1.11 -19.8 0 0 358.20,186.54 Q 358.07,187.36 358.48,188.02 Q 358.78,188.51 359.61,188.79 Q 363.02,189.93 366.41,191.13 Q 369.94,192.38 369.52,196.25 Q 369.13,199.81 365.80,201.02 Q 358.00,203.87 355.00,196.90 A 0.72,0.71 57.4 0 1 355.17,196.09 Q 357.47,194.01 358.60,197.27 A 1.59,1.27 -87.3 0 0 359.16,198.04 Q 363.84,201.33 366.28,197.07 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 713.37,183.35 Q 717.62,180.02 722.81,183.19 Q 724.58,184.27 724.48,186.43 A 0.94,0.91 77.1 0 1 724.01,187.21 Q 722.43,188.06 721.50,186.50 Q 719.56,183.24 716.00,184.75 Q 712.49,186.24 714.67,188.13 Q 715.45,188.82 717.15,189.34 Q 719.62,190.09 722.06,190.94 Q 724.98,191.95 725.29,195.02 Q 725.77,199.96 721.06,201.32 Q 713.69,203.46 710.93,196.96 A 0.83,0.82 54.6 0 1 711.07,196.11 Q 712.81,194.16 713.84,196.20 Q 714.54,197.57 715.07,198.01 Q 718.66,201.02 721.95,197.44 Q 723.91,195.31 721.20,194.32 Q 718.92,193.48 716.62,192.70 Q 713.36,191.60 712.36,190.53 Q 710.84,188.90 711.31,186.53 Q 711.69,184.67 713.37,183.35 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 222.61,193.31 Q 223.40,200.15 229.79,198.82 A 2.73,2.72 8.2 0 0 231.63,197.45 Q 232.86,195.14 235.18,195.58 A 0.74,0.74 16.3 0 1 235.73,196.58 Q 234.24,200.37 230.42,201.35 Q 225.95,202.50 222.98,200.17 Q 220.56,198.26 219.77,194.56 Q 219.03,191.15 220.11,187.80 Q 221.13,184.66 223.57,183.08 Q 226.43,181.24 229.83,182.13 Q 233.00,182.96 234.65,185.68 Q 236.34,188.47 235.96,192.01 A 0.85,0.83 3.0 0 1 235.12,192.75 L 223.05,192.80 A 0.46,0.45 86.0 0 0 222.61,193.31 ZM 230.31,185.19 Q 224.96,183.09 222.91,188.74 A 0.95,0.90 -80.3 0 0 223.76,190.01 L 230.01,189.80 Q 230.51,189.79 230.96,189.95 Q 232.53,190.50 232.69,188.81 Q 232.93,186.21 230.31,185.19 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 279.55,200.57 L 279.45,208.18 A 0.78,0.72 81.3 0 1 278.98,208.88 Q 276.59,209.78 276.75,207.00 Q 277.00,202.71 276.57,183.07 A 0.66,0.61 83.6 0 1 277.05,182.42 Q 278.75,182.03 279.75,183.56 A 0.41,0.41 53.0 0 0 280.37,183.65 Q 282.87,181.45 285.89,181.95 Q 288.41,182.37 290.17,184.88 Q 293.27,189.33 292.01,194.57 Q 291.01,198.69 288.15,200.57 Q 284.61,202.89 279.99,200.31 A 0.30,0.30 -75.1 0 0 279.55,200.57 ZM 284.1536,199.1891 A 7.41,4.94 90.9 0 0 289.2094,191.8576 A 7.41,4.94 90.9 0 0 284.3864,184.3709 A 7.41,4.94 90.9 0 0 279.3306,191.7024 A 7.41,4.94 90.9 0 0 284.1536,199.1891 Z"></path>
                                <path fill="#b4b8b6"
                                      d="M 309.21,184.05 Q 310.79,181.07 314.28,182.10 A 1.10,0.98 -0.6 0 1 314.97,182.70 Q 315.51,184.02 314.59,185.14 A 0.92,0.76 22.0 0 1 313.76,185.40 Q 309.77,184.70 308.92,187.79 Q 308.73,188.45 308.54,192.60 Q 308.42,195.36 308.48,198.12 Q 308.48,198.21 308.68,199.85 Q 308.96,202.06 306.22,201.17 A 0.71,0.69 9.3 0 1 305.73,200.51 L 305.73,183.01 A 0.73,0.69 81.9 0 1 306.22,182.32 Q 308.23,181.71 308.88,184.02 Q 309.01,184.45 309.21,184.05 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 392.45,194.40 L 384.14,191.03 A 1.55,1.22 81.9 0 1 383.44,190.36 Q 379.85,183.52 387.76,182.03 Q 392.69,181.10 395.19,184.74 Q 395.83,185.67 395.68,186.52 Q 395.44,187.84 393.78,187.35 A 1.52,1.14 75.3 0 1 393.05,186.77 Q 390.33,182.64 386.06,185.31 Q 384.53,186.27 385.39,187.64 Q 385.85,188.37 387.04,188.82 Q 389.39,189.73 391.82,190.40 Q 397.25,191.89 396.58,196.34 Q 396.12,199.36 393.55,200.70 Q 391.11,201.97 387.75,201.50 Q 383.21,200.87 382.12,196.99 A 1.48,1.27 53.5 0 1 382.19,196.03 Q 382.87,194.68 384.12,195.41 Q 384.62,195.71 385.21,196.72 Q 386.99,199.78 390.75,199.00 Q 394.30,198.26 393.13,195.03 A 1.28,0.95 6.3 0 0 392.45,194.40 Z"></path>
                                <path fill="#b4b8b6"
                                      d="M 521.81,184.13 Q 522.36,183.96 522.51,183.30 A 1.15,0.82 88.9 0 1 523.01,182.57 Q 524.84,181.68 526.94,181.98 A 0.97,0.83 -5.7 0 1 527.68,182.50 Q 528.16,183.60 527.49,184.96 A 0.69,0.68 -71.5 0 1 526.76,185.33 Q 524.58,184.98 523.19,185.84 Q 522.04,186.55 521.78,188.66 Q 520.84,196.07 521.50,199.00 Q 522.16,201.91 518.90,201.17 A 0.84,0.84 -83.5 0 1 518.25,200.35 L 518.25,183.06 A 0.78,0.77 86.2 0 1 518.92,182.29 Q 521.16,182.00 521.31,183.69 A 0.47,0.38 80.2 0 0 521.81,184.13 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 559.33,193.31 Q 559.65,198.77 564.72,198.98 Q 566.22,199.04 567.33,198.02 Q 568.17,197.26 569.44,195.94 Q 571.39,193.90 572.12,196.27 A 1.43,1.32 -44.3 0 1 572.07,197.22 Q 570.92,199.98 568.05,201.02 Q 560.70,203.69 557.31,197.69 Q 555.69,194.81 555.99,191.05 Q 556.29,187.28 558.09,184.90 Q 560.13,182.21 563.68,181.92 Q 566.84,181.66 569.30,183.56 Q 573.08,186.49 572.61,192.06 A 0.56,0.56 -88.3 0 1 572.06,192.58 L 559.80,192.82 A 0.47,0.46 -1.7 0 0 559.33,193.31 ZM 564.48,189.96 Q 566.36,190.09 568.96,189.45 A 0.63,0.62 -17.5 0 0 569.40,188.62 Q 567.15,182.92 561.91,185.51 Q 559.77,186.56 559.75,189.39 A 0.55,0.55 3.4 0 0 560.25,189.95 Q 561.43,190.07 562.39,189.98 Q 563.46,189.88 564.48,189.96 Z"></path>
                                <path fill="#b4b8b6"
                                      d="M 589.85,183.40 Q 592.02,181.37 594.95,182.16 A 0.89,0.83 2.3 0 1 595.58,182.79 Q 595.83,183.86 595.28,184.83 A 0.80,0.72 17.5 0 1 594.51,185.18 Q 590.33,184.65 589.43,187.76 Q 589.24,188.42 589.06,192.62 Q 588.90,196.55 588.99,200.49 A 0.70,0.64 81.8 0 1 588.53,201.16 Q 585.74,202.00 585.91,199.27 Q 586.00,197.81 586.00,197.72 Q 585.96,190.39 586.05,183.06 A 0.75,0.68 82.4 0 1 586.52,182.38 Q 588.21,181.84 589.24,183.32 A 0.41,0.40 51.6 0 0 589.85,183.40 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 654.69,185.19 Q 657.07,181.71 661.05,181.83 Q 664.73,181.94 666.96,184.44 Q 669.40,187.19 669.21,192.04 A 0.73,0.72 -88.4 0 1 668.48,192.74 L 656.94,192.69 A 0.58,0.55 2.8 0 0 656.37,193.18 Q 655.89,197.19 659.79,198.64 Q 663.45,199.99 665.19,196.72 Q 666.06,195.08 668.38,195.58 A 0.88,0.88 -71.5 0 1 668.99,196.81 Q 667.50,200.09 664.21,201.11 Q 659.55,202.57 656.37,200.22 Q 653.64,198.21 652.85,194.12 Q 651.91,189.26 654.69,185.19 ZM 665.12,189.57 A 0.87,0.83 83.2 0 0 665.88,188.56 Q 665.11,183.96 660.70,184.60 Q 658.72,184.89 657.61,186.07 Q 656.30,187.46 656.40,189.57 A 0.52,0.51 87.3 0 0 656.94,190.07 L 665.12,189.57 Z"></path>
                                <path fill="#b4b8b6"
                                      d="M 692.85,185.25 Q 689.95,183.68 687.77,185.56 Q 686.36,186.77 686.05,189.59 Q 685.21,197.21 685.75,199.00 Q 686.71,202.18 683.41,201.21 A 0.68,0.66 8.1 0 1 682.92,200.57 L 682.80,183.07 A 0.72,0.62 83.4 0 1 683.27,182.38 Q 684.51,182.06 685.69,183.72 A 0.51,0.51 50.7 0 0 686.45,183.80 Q 688.98,181.43 692.52,181.86 Q 694.87,182.14 696.12,183.79 Q 696.97,184.91 697.23,187.41 Q 697.91,193.94 697.30,200.50 A 0.83,0.73 85.0 0 1 696.79,201.19 Q 695.81,201.51 694.92,201.03 A 0.93,0.79 -77.8 0 1 694.50,200.25 L 694.50,188.01 Q 694.50,186.13 692.85,185.25 Z"></path>
                                <path fill="#b2b3b3"
                                      d="M 256.29,188.09 Q 257.75,186.00 259.01,183.78 Q 260.14,181.80 263.09,182.36 A 0.41,0.41 22.7 0 1 263.35,182.99 L 258.22,190.69 A 1.23,1.23 46.4 0 0 258.19,191.99 Q 260.39,195.64 262.35,198.52 Q 262.70,199.03 263.43,199.71 Q 265.45,201.61 261.46,201.22 A 2.15,2.15 -12.8 0 1 259.81,200.18 L 256.51,194.68 A 0.36,0.36 49.6 0 0 255.93,194.63 Q 254.08,196.86 252.58,199.34 Q 251.71,200.78 250.84,201.07 Q 249.73,201.44 248.17,201.14 A 0.42,0.41 -67.1 0 1 247.92,200.50 L 253.54,192.28 A 1.60,1.50 46.2 0 0 253.52,190.56 L 248.59,183.02 A 0.35,0.35 -21.9 0 1 248.82,182.49 Q 252.06,181.83 252.84,183.71 Q 253.87,186.18 255.66,188.14 A 0.41,0.40 -49.1 0 0 256.29,188.09 Z"></path>
                                <path fill="#aaabab" fillOpacity="0.996"
                                      d="M 500.45,199.69 Q 499.92,199.88 499.52,200.28 Q 499.04,200.76 498.70,200.92 Q 493.79,203.12 490.95,199.83 Q 489.50,198.15 489.36,194.75 Q 489.12,189.18 489.26,183.32 A 1.04,1.03 -4.9 0 1 490.10,182.33 Q 492.68,181.82 492.43,183.55 Q 492.29,184.53 492.29,184.60 Q 492.08,189.80 492.25,195.01 Q 492.40,199.57 496.75,199.00 Q 500.54,198.50 500.84,194.26 Q 501.24,188.75 500.79,183.24 A 0.83,0.74 74.8 0 1 501.17,182.48 Q 502.37,181.81 503.58,182.48 A 0.90,0.80 -77.2 0 1 504.00,183.24 L 503.94,200.20 A 1.00,0.89 74.5 0 1 503.55,200.99 Q 501.88,202.16 501.09,200.04 A 0.55,0.48 69.7 0 0 500.45,199.69 Z"></path>
                                <rect fill="#aaabab" fillOpacity="0.996" x="539.51" y="182.28" width="3.26" height="18.96"
                                      rx="0.50"></rect>
                                <rect fill="#b4b8b6" x="-1.50" y="-9.46" transform="translate(637.96,191.76) rotate(0.1)" width="3.00"
                                      height="18.92" rx="0.34"></rect>
                        </svg>
                </div>

        </>



    );
};

export default Logo;