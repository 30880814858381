import React, {useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import 'leaflet/dist/leaflet.css';
import Home from './pages/Home';
import Jobs from './pages/Jobs';
import Intro from "./components/Intro";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {changeIntro} from "./redux/slices/appSlice";
import {useDispatchEx} from "./hooks/redux";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
gsap.registerPlugin(ScrollTrigger);
function App() {
    const [isLoading,setLoading] = useState(true);
    const dispatch = useDispatchEx();
    let timeline = gsap.timeline();
    useEffect(()=>{
        setTimeout(()=>{
            const UpStart = {opacity:1,duration: 1.5, stagger: 0.5};
            const InFinish = {opacity:0,duration: 1.5, stagger: 0.5};
            timeline.fromTo(".intro", UpStart, InFinish, 0.5)
        },3000)
        setTimeout(()=>{
            dispatch(changeIntro(false));
            setLoading(false);
        },6000)
    },[])

  return (
    <>
        {isLoading &&  <Intro/>}
        <Routes>
            <Route path="/" element={ <Home />}/>
            <Route path="/jobs" element={ <Jobs />}/>
            <Route path="/impressum" element={ <Impressum />}/>
            <Route path="/datenschutz" element={ <Datenschutz />}/>
            <Route path="*" element={<Home/>} />
        </Routes>
    </>
  );
}

export default App;
