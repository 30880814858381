import React from 'react';

const WaysIcon = () => {
    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M69.0714 17.4075C65.9204 17.4075 63.3571 14.8439 63.3571 11.6932C63.3571 8.54227 65.9204 5.97894 69.0714 5.97894C72.2223 5.97894 74.7857 8.54227 74.7857 11.6932C74.7857 14.8439 72.2223 17.4075 69.0714 17.4075ZM69.0714 0.264652C62.76 0.264652 57.6428 5.38179 57.6428 11.6932C57.6428 18.0046 62.76 23.1218 69.0714 23.1218C75.3828 23.1218 80.5 18.0046 80.5 11.6932C80.5 5.38179 75.3828 0.264652 69.0714 0.264652Z" fill="#6699CC"></path>
            <path d="M21.6831 24.3049L17.6429 20.2647L11.0914 26.8161L4.54024 20.2647L0.5 24.3049L7.05143 30.8559L0.5 37.4075L4.54024 41.4478L11.0914 34.8963L17.6429 41.4478L21.6831 37.4075L15.1317 30.8559L21.6831 24.3049Z" fill="#6699CC"></path>
            <path d="M37.6428 37.4075H43.3571V43.1218H37.6428V37.4075Z" fill="#6699CC"></path>
            <path d="M37.6428 25.979H43.3571V31.6932H37.6428V25.979Z" fill="#6699CC"></path>
            <path d="M40.4999 0.264647L29.0713 14.5504H37.6427V20.2646H43.357V14.5504H51.9284L40.4999 0.264647Z" fill="#6699CC"></path>
            <path d="M11.9286 74.5504C8.77762 74.5504 6.21428 71.9868 6.21428 68.8361C6.21428 65.6851 8.77762 63.1218 11.9286 63.1218C15.0795 63.1218 17.6429 65.6851 17.6429 68.8361C17.6429 71.9868 15.0795 74.5504 11.9286 74.5504ZM69.0714 28.8361L57.6428 43.1218H66.2143V49.9732C66.2143 58.7989 59.0343 65.9789 50.2088 65.9789H36.2683C40.6117 62.0049 43.3571 56.3101 43.3571 49.9732V48.8361H37.6428V49.9732C37.6428 58.3618 31.1538 65.2504 22.9312 65.913C21.6348 61.0273 17.2224 57.4075 11.9286 57.4075C5.61714 57.4075 0.5 62.5247 0.5 68.8361C0.5 75.1475 5.61714 80.2646 11.9286 80.2646C17.2486 80.2646 21.6774 76.6132 22.9545 71.6932H50.2117C62.1852 71.6932 71.9286 61.9499 71.9286 49.9732V43.1218H80.5L69.0714 28.8361Z" fill="#6699CC"></path>
        </svg>
    );
};

export default WaysIcon;