import React, {useEffect, useRef} from 'react';
import IconBlock from "../UI/IconBlock";
import WaysIcon from "../icons/info/WaysIcon";
import LampIcon from "../icons/info/LampIcon";
import ChatIcon from "../icons/info/ChatIcon";
import Button from "../UI/Button";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {gtagEvent} from "../../helpers/scripts";

const ContactSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline.fromTo(".contact-section__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".contact-section__item", UpStart, InFinish, 0.3)
        timeline.fromTo(".contact-section__buttons", UpStart, InFinish, 0.6)
        ;

    },[refTarget]);
    return (
        <section className={'contact-section'} ref={refTarget}>
            <div className="container">
                <div className="contact-section__header">
                    <h3 className="h3">
                        Da drauf kannst Du Dich freuen
                    </h3>
                </div>
                <div className="contact-section__wrap">
                    <div className="contact-section__items">
                        <div className="contact-section__item">
                            <IconBlock icon={<WaysIcon/>} title={'Handlungsspielräume'}/>
                        </div>
                        <div className="contact-section__item">
                            <IconBlock icon={<LampIcon/>} title={'Ideen einbringen'}/>
                        </div>
                        <div className="contact-section__item">
                            <IconBlock icon={<ChatIcon/>} title={'Onboarding'}/>
                        </div>
                    </div>
                    <div className="contact-section__buttons">
                        <Button onClick={()=>{gtagEvent('click','contact_per_email')}} type={'htmlLink'} href={"mailto:info@adr-trans.de"} className={'btn-small contact-section__btn'} name={'Kontakt per E-Mail'}/>
                        <Button onClick={()=>{gtagEvent('click','contact_per_whatsapp')}} type={'htmlLink'} href={"https://wa.me/49017670066644"} className={'btn-small contact-section__btn'} name={'Kontakt per Whatsapp'}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;