import React, {FC, useEffect, useState} from 'react';
import Header from '../Header';
import Footer from "../Footer";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import CookiesInfo from "../CookiesInfo";
import ModalCookies from "../modals/modalCookies";
import {changeModalCookies, changeModalErrorMessage, changeModalSuccess} from "../../redux/slices/appSlice";
import ModalSuccess from "../modals/modalSuccess";
import Cookies from "js-cookie";
interface ILayout {
    children: React.ReactNode;
}
const Layout:FC<ILayout> = ({children}) => {
    const [header,setHeader] = useState(false);
    const [outHeader,setOutHeader] = useState(false);
    const openMenu = useSelectorEx(state => state.app.openMenu)
    useEffect(()=>{
        let prevScroll = 0;
        window.addEventListener("scroll", ()=>{
            if(window.scrollY > 152 && window.scrollY > prevScroll && !openMenu){
                setOutHeader(true);
            }
            else{
                setOutHeader(false);
            }

            setHeader(window.scrollY > 15);
            prevScroll = window.scrollY;
        });

    },[openMenu]);
    useEffect(()=>{
        window.scrollTo({top:0, behavior: 'smooth' });
    },[]);

    const dispatch = useDispatchEx();
    const {modalCookies,modalSuccess,modalErrorMessage} = useSelectorEx(state => state.app)
    return (
        <>
            <Header attach={header} out={outHeader} />
            <main >
                {children}
            </main>
            <ModalCookies active={modalCookies} onClose={()=>{
               // localStorage.setItem('cookiesInfo',"true");
                Cookies.set('cookiesInfo', 'true', { expires: 31 })
                dispatch(changeModalCookies(false));
            }} />
            <ModalSuccess active={modalSuccess} header={'Die Anfrage wurde gesendet'} onClose={()=> dispatch(changeModalSuccess(false))}>
                Vielen Dank für die Übermittlung des Formulars!
                Wir haben Ihre Informationen erhalten und werden uns so schnell wie möglich bei Ihnen melden.
                Wir schätzen Ihr Interesse und freuen uns darauf, bald mit Ihnen in Verbindung zu treten.
            </ModalSuccess>
            <ModalSuccess active={modalErrorMessage} header={'Oh nein!'} onClose={()=> dispatch(changeModalErrorMessage())}>
                Oh nein! Beim Absenden Ihres Formulars ist etwas schief gelaufen.
            </ModalSuccess>
            {/*{/*!localStorage.getItem('cookiesInfo') && <CookiesInfo/>}*/}
            {!Cookies.get("cookiesInfo") && <CookiesInfo/>}
            <Footer/>
        </>
    );
};

export default Layout;