import React, {FC} from 'react';

interface IIconBlock{
    icon: React.ReactNode;
    title: React.ReactNode;
}

const IconBlock:FC<IIconBlock> = ({icon,title}) => {
    return (
        <div className={'icon-block'}>
            <div className="icon-block__img">
                {icon}
            </div>
            <div className="icon-block__title">
                {title}
            </div>
        </div>
    );
};

export default IconBlock;