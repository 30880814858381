import React from 'react';

const UserArrows = () => {
    return (
        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 31.3291C24.1358 31.3291 25.8672 29.5977 25.8672 27.4619C25.8672 25.3261 24.1358 23.5947 22 23.5947C19.8642 23.5947 18.1328 25.3261 18.1328 27.4619C18.1328 29.5977 19.8642 31.3291 22 31.3291Z" fill="#1C3D76"/>
            <path d="M22 31.3291C18.4462 31.3291 15.5547 34.2206 15.5547 37.7744V44.2197H28.4453V37.7744C28.4453 34.2206 25.5538 31.3291 22 31.3291ZM44 17.1494L33.6016 9.59551V14.5713H29.7344C26.9639 14.5713 24.171 15.6878 21.9937 18.0457C21.1409 19.0296 20.4935 20.1526 20.0575 21.3487C20.6744 21.1518 21.3187 21.0166 22 21.0166C23.2686 21.0166 24.4448 21.3976 25.4433 22.0332C26.3682 20.6453 27.9446 19.7275 29.7344 19.7275H33.6016V24.7033L44 17.1494ZM19.4219 13.0811C19.874 13.2796 20.3197 13.4934 20.7462 13.7442C21.1741 13.9794 21.5915 14.2877 22.007 14.5984C22.4461 14.2682 22.886 13.9485 23.3368 13.7001C23.7367 13.4709 24.1557 13.2775 24.578 13.0926V10.7041H29.5538L22 0.219727L14.4461 10.7041H19.4219V13.0811ZM17.4799 20.8941L17.6356 20.4665C18.1865 18.9531 18.9949 17.5759 20.0362 16.3712C18.3815 15.2728 16.6081 14.5713 14.2656 14.5713H10.3984V9.59551L0 17.1494L10.3984 24.7033V19.7275H14.2656C15.4889 19.7275 16.5953 20.1793 17.4799 20.8941Z" fill="#1C3D76"/>
        </svg>
    );
};

export default UserArrows;