import React from 'react';
import { Link } from 'react-router-dom';
import shortLogo from '../assets/images/logoShort.svg'
import {gtagEvent} from "../helpers/scripts";

const Footer = () => {
    return (
        <footer className={'footer'}>
            <div className="container">
                <div className="footer__wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-8">
                            <div className="footer__inner">
                                <div className="footer__logo">
                                    <a href="/">
                                        <img src={shortLogo} alt="logo" />
                                    </a>
                                </div>
                                <div className="footer__links">
                                    <div className="footer__header">Links</div>
                                    <ul className={'footer__list'}>
                                        <li><Link to="/">Uber uns</Link></li>
                                        <li><Link to="/#services">Leistungen</Link></li>
                                        <li><Link to="/jobs">Karriere</Link></li>
                                    </ul>
                                </div>
                                <div className="footer__links d-md-none d-block">
                                    <div className="footer__contact">
                                        <div className="footer__header">Kontaktieren Sie uns</div>
                                        <div className={'footer__description'}>
                                            <a href={'tel:49021516038132'}>+49 (0) 2151 603 81 32</a>
                                           {/* <a href={'mailto:adrtransservice@gmail.com'}>adrtransservice@gmail.com</a>*/}
                                            <a href={'mailto:info@adr-trans.de'}>info@adr-trans.de</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer__links">
                                    <div className="footer__header">Datenschutzerklärung</div>
                                    <ul className={'footer__list'}>
                                        <li><Link to="/impressum">Impressum</Link></li>
                                        <li><Link to="/datenschutz">Datenschutz</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4 d-none d-md-block">
                            <div className="footer__contact-wrap">
                                <div className="footer__contact">
                                    <div className="footer__header">Kontaktieren Sie uns</div>
                                    <div className={'footer__description'}>
                                        <a onClick={()=>{gtagEvent('click','phone_number_tapped')}} href={'tel:49021516038132'}>+49 (0) 2151 603 81 32</a>

                                        <a onClick={()=>{gtagEvent('click','email_tapped')}} href={'mailto:info@adr-trans.de'}>info@adr-trans.de</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__copyright">
                    2023 A.d.R. Transservice
                </div>
            </div>
        </footer>
    );
};

export default Footer;