import React, {useEffect, useRef} from 'react';
import Button from '../UI/Button';
import Input from "../UI/Input";
import Select from "../UI/Select";
import Textarea from "../UI/Textarea";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import { useDispatchEx } from '../../hooks/redux';
import { FormikHelpers, useFormik } from 'formik';
import axios from "axios";
import * as yup from "yup";
import {changeModalErrorMessage, changeModalSuccess} from "../../redux/slices/appSlice";
import {gtagEvent} from "../../helpers/scripts";
import {Link} from "react-router-dom";
const FormSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline
            .fromTo(".form-section__header", UpStart, InFinish, 0.1)
            .fromTo(".form-section__form", UpStart, InFinish, 0.4)
        ;
    },[]);

    const valSchema = yup.object().shape({
        sex: yup.string().oneOf(['Herr', 'Frau', 'Divers', ""]),
        first_name: yup.string().min(2,'Das Feld muss mindestens 2 Zeichen lang sein').required("Vorname ist obligatorisch"),
        second_name:yup.string().min(2,'Das Feld muss mindestens 2 Zeichen lang sein').required("Nachname ist obligatorisch"),
        company_name:yup.string().min(2,'Das Feld muss mindestens 2 Zeichen lang sein').required("Firmenname ist obligatorisch"),

        email: yup.string().email('E-Mail-Format ist falsch').required("E-Mail ist obligatorisch"),
        land: yup.string(),
        comment: yup.string(),
    });
    const {errors,touched,values,handleChange,handleBlur,handleSubmit,setFieldValue,resetForm,setValues} = useFormik({
        initialValues: {
            sex: "",
            first_name: '',
            second_name:'',
            company_name:'',
            email: '',
            land: '',
            comment: ""

        },
        enableReinitialize: true,
        validationSchema:valSchema,
        onSubmit: (value,helper) => handleForm(value,helper),
    });

    const dispatch = useDispatchEx();
    let handleForm = async (values:any, helper:FormikHelpers<any>)=>{
        try {
           const {sex, first_name, second_name, company_name, email, land, comment} = values;
            await axios.post(`mails.php`,{
                sex,
                first_name,
                second_name,
                company_name,
                email,
                land,
                comment
            });
            helper.resetForm();
            dispatch(changeModalSuccess(true));
        }
        catch (e) {
            dispatch(changeModalErrorMessage());
        }
    };
    const europeanCountries = [
        "Albania",
        "Andorra",
        "Austria",
        "Belgium",
        "Bosnia and Herzegovina",
        "Bulgaria",
        "Croatia",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Estonia",
        "Finland",
        "France",
        "Germany",
        "Greece",
        "Hungary",
        "Iceland",
        "Ireland",
        "Italy",
        "Kosovo",
        "Latvia",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Malta",
        "Moldova",
        "Monaco",
        "Montenegro",
        "Netherlands",
        "North Macedonia",
        "Norway",
        "Poland",
        "Portugal",
        "Romania",
        "San Marino",
        "Serbia",
        "Slovakia",
        "Slovenia",
        "Spain",
        "Sweden",
        "Switzerland",
        "Ukraine",
        "United Kingdom",
        "Vatican City"
    ];
    const countriesList = europeanCountries.map(item=>{
        return {name:item,value:item}
    })
    return (
        <section className={'form-section'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-section__header">
                            <div className="h2-subtitle form-section__h2-subtitle">//Service</div>
                            <h2 className={'h2 h2-line form-section__h2'}>
                                <span>STEllen sie uns</span> ihre anfrage</h2>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-section__form">
                            <form onSubmit={handleSubmit}>
                                <div className="form">
                                    <div className="form__input">
                                        {/*'Herr','Hemm' Herr/Frau/Divers*/}
                                       <Select className={errors.sex && touched.sex ? "error": ""} onChange={(value)=> setFieldValue('sex',value)} placeholder={'Anrede'} items={[
                                           {name:'Herr',value:'Herr'},
                                           {name:'Frau',value:'Frau'},
                                           {name:'Divers',value:'Divers'}
                                       ]}  />
                                        {errors.sex && touched.sex && <p style={{color:"#ff0000"}}>{errors.sex}</p>}
                                    </div>
                                    <div className="form__input">
                                        <Input className={errors.first_name && touched.first_name ? "error": ""} value={values.first_name} onBlur={handleBlur} onChange={handleChange} name={'first_name'} placeholder={'Vorname*'} />
                                        {errors.first_name && touched.first_name && <p style={{color:"#ff0000"}}>{errors.first_name}</p>}
                                    </div>
                                    <div className="form__input">
                                        <Input className={errors.second_name && touched.second_name ? "error": ""} value={values.second_name} onBlur={handleBlur} onChange={handleChange} name={'second_name'} placeholder={'Nachname*'} />
                                        {errors.second_name && touched.second_name && <p style={{color:"#ff0000"}}>{errors.second_name}</p>}
                                    </div>
                                    <div className="form__input">
                                        <Input className={errors.company_name && touched.company_name ? "error": ""} value={values.company_name} onBlur={handleBlur} onChange={handleChange} name={'company_name'} placeholder={'Firmenname *'} />
                                        {errors.company_name && touched.company_name && <p style={{color:"#ff0000"}}>{errors.company_name}</p>}
                                    </div>
                                    <div className="form__input">
                                        <Select className={errors.land && touched.land ? "error": ""} onChange={(value) => setFieldValue('land',value) } placeholder={'Land/Region'} items={countriesList}  />
                                    </div>
                                    <div className="form__input">
                                        <Input className={errors.email && touched.email ? "error": ""} value={values.email} onBlur={handleBlur} onChange={handleChange} name={'email'} placeholder={'E-mail*'} />
                                        {errors.email && touched.email && <p style={{color:"#ff0000"}}>{errors.email}</p>}
                                    </div>
                                    <div className="form__input">
                                        <Textarea className={errors.comment && touched.comment ? "error": ""} value={values.comment} onBlur={handleBlur} onChange={handleChange} name={'comment'} placeholder={'Kommentar'} />
                                        {errors.comment && touched.comment && <p style={{color:"#ff0000"}}>{errors.comment}</p>}
                                    </div>
                                    <div className="form__description">
                                        Informationen zum Umgang mit Ihren personenbezogenen Daten
                                        finden Sie in unserer <Link to="/datenschutz">Datenschutzerklärung</Link>
                                    </div>
                                    <div className="form__button">
                                        <Button onClick={()=>{gtagEvent('click','anfrage_senden_homepage')}} type={'button'} name={'ANFRAGE SENDEN'} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FormSection;