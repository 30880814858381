import React, {useEffect, useRef, useState} from 'react';
import ImageBlock from "../UI/ImageBlock";
import driverImg from "../../assets/images/driver.jpg"
import List from '../UI/List';
import Button from "../UI/Button";
import LinkArrow from "../icons/LinkArrow";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import InfoSection from "./InfoSection";
import ButtonSpread from "../ButtonSpread";

const DriverSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom-=100",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.5, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.5, stagger: 0.5};
        timeline.fromTo(".driver__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".driver__img", UpStart, InFinish, 0.3)
        timeline.fromTo(".driver__content", UpStart, InFinish, 0.5)
        ;

    },[refTarget]);
    const [open,setOpen] = useState(false);
    return (
        <section className={'driver'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="driver__wrap">
                            <div className="driver__header">
                                <div className="h2-subtitle">//vacancy</div>
                                <h2 className="h2 h2-line driver__h2"><span>BERUFSKRAFTFAHRER (W/M/D)</span></h2>
                            </div>
                            <div className="driver__img d-lg-none d-block">
                                <ImageBlock width={529} height={434} src={driverImg}/>
                                <div className="driver__button">
                                    <ButtonSpread />
                                </div>
                            </div>
                            <div className="driver__content">
                                <List className={'driver__list'} items={["Eine abwechslungsreiche Tätigkeit mit viel Kundenkontakt in einem wachstumsstarken Umfeld",
                                    "Be- und Entladung Ihres Fahrzeuges in Eigenregie mit anschließender Fahrt zu unseren Kunden",
                                    "Schnittstellenkontrolle sowie Ladungssicherung von Transportgütern",
                                    "Repräsentation unseres Unternehmens bei unseren Kunden"]} />
                                <div className="driver__more">
                                    <a className={'link-arrow'} onClick={()=>setOpen(!open)}>Mehr <LinkArrow/></a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="driver__img-wrapper">
                            <div className="driver__img d-none d-lg-block">
                                <ImageBlock width={529} height={434} src={driverImg}/>
                                <div className="driver__button">
                                    <ButtonSpread />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <InfoSection
                active={open}
                description={'Wir bewegen Großes und sind selbst ständig in Bewegung. Damit das so bleibt, suchen wir Sie zum nächstmöglichen Zeitpunkt.  Gemeinsam machen wir Deutschlands führendes Unternehmen und innovative Logistiklösungen noch erfolgreicher.'}
                firstListHeader={'DAS ERWARTET SIE BEI UNS'}
                firstList={[
                    'Eine abwechslungsreiche Tätigkeit mit viel Kundenkontakt in einem wachstumsstarken Umfeld',
                    'Be- und Entladung Ihres Fahrzeuges in Eigenregie mit anschließender Fahrt zu unseren Kunden',
                    'Schnittstellenkontrolle sowie Ladungssicherung von Transportgütern',
                    'Repräsentation unseres Unternehmens bei unseren Kunden'
                ]}
                secondListHeader={'DAS BRINGEN SIE MIT'}
                secondList={[
                    'Führerschein der Klasse C/CE, eine Fahrerkarte sowie ein einwandfreies Führungszeugnis',
                    'Idealerweise bereits absolvierte Module der Berufskraftfahrerqualifizierung',
                    'Einschlägige Berufserfahrungen sind von Vorteil - Quereinsteiger sind ebenso willkommen',
                    'Kommunikationsfähigkeit und gute Umgangsformen'
                ]}
            />
        </section>
    );
};

export default DriverSection;