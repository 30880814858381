import React from 'react';
import Layout from "../components/sections/Layout";
import Preloader from "../components/Preloader";
import usePreloader from "../hooks/usePreloader";

const Impressum = () => {
    const isLoading = usePreloader([]);
    return (
        <>
            {isLoading && <Preloader/>}
            <Layout>
                <section className="info-political">
                    <div className="container">
                        <div className="info-political__wrapper">
                            <h1 className={'h1'}>Impressum</h1>

                            <h2 className={'h2'}>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                            <p>A.d.R. Transservice GmbH<br />
                                Leyentalstrasse 61<br />
                                47799 Krefeld</p>

                            <p>Handelsregister: HRB 13597<br />
                                Registergericht: Amtsgericht Krefeld</p>

                            <p><strong>Vertreten durch:</strong><br />
                                Alexander Achmetov<br />
                                Ruslan Sagimbayev</p>

                            <h2 className={'h2'}>Kontakt</h2>
                            <p>Telefon: +49 (0) 2151 603 81 32<br />
                                Telefax: +49 (0) 2151 603 96 35<br />
                                E-Mail: info@adr-trans.de</p>

                            <h2 className={'h2'}>Umsatzsteuer-ID</h2>
                            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                                DE277759480</p>

                            <h2 className={'h2'}>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                            <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>

                        </div>
                    </div>
                </section>
            </Layout>
        </>

    );
};

export default Impressum;