import React, {FC, useEffect, useRef} from 'react';

import mainImg from '../../assets/images/main.png';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useSelectorEx} from "../../hooks/redux";
gsap.registerPlugin(ScrollTrigger);
const MainSection:FC = () => {
    const preloader = useSelectorEx(state => state.app.preloader)
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
           /* scrollTrigger:{
                trigger: refTarget.current,
              //  start: "top top",
            }*/
        });
        if(!preloader){
            const UpStart = {y:-50,opacity:0,duration: 1.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 1.5, stagger: 0.5};
          /*  const UpStartLeft = {x:-400,scale:0.5,opacity:0,duration: 2.5, stagger: 0.5};
            const InFinishLeft = {x:0,scale:1,opacity:1,duration: 2.5, stagger: 0.5};*/
            timeline.fromTo(".main__header", UpStart, InFinish, 0.1)
            timeline.fromTo(".main__img img", UpStart, InFinish, 0.5);
        }
    },[preloader]);
    return (
        <section className={'main'} ref={refTarget}>
            <div className="container">
                <div className="main__wrap">
                    <h1 className="h1 main__header">Effiziente Transport <span>Lösungen</span></h1>
                    <div className="main__img">
                        <img src={mainImg} alt=""/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainSection;