import React, {useEffect, useRef} from 'react';
import DigitBlock from "../UI/DigitBlock";
import Hands from "../icons/advantages/Hands";
import HandCheck from "../icons/advantages/HandCheck";
import Run from '../icons/advantages/Run';
import UserArrows from "../icons/advantages/UserArrows";
import Puzzle from '../icons/advantages/Puzzle';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const TopFiveSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {y:50,opacity:0,duration: 0.3, stagger: 0.3};
        const InFinish = {y:0,opacity:1,duration: 0.3, stagger: 0.3};
        timeline.fromTo(".top-section__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".top-section__item", UpStart, InFinish, 0.5)
        ;

    },[refTarget]);
    return (
        <section className={'top-section'} ref={refTarget}>
            <div className="container">
                <div className="top-section__header">
                    <h2 className="h2 top-section__h2">top 5 <span>Vorteile</span> auf einen Blick</h2>
                </div>
                <div className="top-section__wrap">
                    <DigitBlock className={'top-section__item'} id={1} icon={<Hands/>} description={'Kundenorientierter Service: Unser freundlicher und kompetenter Kundensupport steht Ihnen jederzeit zur Verfügung, um Ihre Fragen zu beantworten und Lösungen für Ihre Anliegen zu finden.'}/>
                    <DigitBlock className={'top-section__item'} id={2} icon={<Run/>} description={'Zuverlässigkeit und Pünktlichkeit: Unser engagiertes Team stellt sicher, dass Ihre Fracht termingerecht und sicher an ihrem Bestimmungsort ankommt.\n'}/>
                    <DigitBlock className={'top-section__item'} id={3} icon={<HandCheck/>} description={'Branchenführende Expertise: Profitieren Sie von unserem umfangreichen Fachwissen und langjähriger Erfahrung in der Logistikbranche.'}/>
                    <DigitBlock className={'top-section__item'} id={4} icon={<UserArrows/>} description={'Individuelle Lösungen: Wir bieten maßgeschneiderte Transport- und Logistiklösungen, die genau auf die Bedürfnisse Ihres Unternehmens zugeschnitten sind.'}/>
                    <DigitBlock className={'top-section__item'} id={5} icon={<Puzzle/>} description={'Flexibilität und Anpassungsfähigkeit: Wie ein Puzzle passen wir unsere Dienstleistungen nahtlos in Ihre Geschäftsprozesse ein und reagieren schnell auf sich ändernde Anforderungen und Marktbedingungen.'}/>
                </div>
            </div>
        </section>
    );
};

export default TopFiveSection;