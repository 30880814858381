import React, {FC} from 'react';
import Close from "../icons/Close";
import Button from "../UI/Button";
import Accordion from "../UI/Accordion";
import Switch from "../UI/Switch";

interface IModal {
    header?: React.ReactNode;
    children: React.ReactNode;
    active: boolean;
    onClose: () => void;
}

const ModalSuccess:FC<IModal> = ({active,header,children,onClose}) => {
    return (
        <div className={`modal ${active ? "active": ""}`}>
            <div className="modal__wrap">
                <div className="modal__close" onClick={onClose}><Close/></div>
                <div className="modal__title">Die Anfrage wurde gesendet</div>
                <div className="modal__body">
                    <div className="modal__description">
                        {children}
                    </div>
                    <div className="modal__content">
                        <div className="modal__info">
                            <div className="modal__buttons">
                                <div className="modal__confirm">
                                    <Button onClick={onClose} className={'btn-small modal__btn'} name={'Ok'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSuccess;