import { useState, useEffect } from "react";
import gsap from "gsap";
import {useDispatchEx, useSelectorEx} from "./redux";
import {changePreloader} from "../redux/slices/appSlice";

const usePreloader = (images:any) => {
    const [isLoading, setIsLoading] = useState(true);
    const timeline = gsap.timeline();
    const intro = useSelectorEx(state => state.app.intro)
    const dispatch = useDispatchEx();
    useEffect(() => {
        Promise.all(
            images.map((img:any) => {
                return new Promise((resolve, reject) => {
                    const image = new Image();
                    image.src = img;
                    image.onload = resolve;
                    image.onerror = reject;
                });
            })
        ).then(() => {
            if(!intro){
                const UpStart = {backgroundColor:"rgba(255, 255, 255, 1)", duration: 0.5, stagger: 0.5 };
                const InFinish = {backgroundColor:"rgba(255, 255, 255, 0)", duration: 0.5, stagger: 0.5 };
                const UpStartX = {position:"absolute", opacity: 1, duration: 0.5, stagger: 0.5 };
                const InFinishX = {  top: 30, left: 0,width:50,height:50, opacity: 1, duration: 0.5, stagger: 0.5 };
                const InFinishXMobile = {  top: 20, left: -5,width:40,height:40, opacity: 1, duration: 0.5, stagger: 0.5 };
                // timeline.fromTo(".preloader__wrap", UpStartX, InFinishX, 0.1);
                timeline.fromTo(".preloader", UpStart, InFinish, 0.1);
                if(window.matchMedia("(max-width: 767px)").matches){
                    timeline.fromTo(".preloader__wrap", UpStartX, InFinishXMobile, 0.1);
                } else {
                    timeline.fromTo(".preloader__wrap", UpStartX, InFinishX, 0.1);
                }
                dispatch(changePreloader(false));
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        });
    }, [images, timeline,intro]);

    return isLoading;
};

export default usePreloader;