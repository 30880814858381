import React from 'react';
//import ReactBodymovin from 'react-bodymovin'
import Lottie from "lottie-react";
import animation from '../assets/effects/Loading.json'
const Preloader = () => {
/*    const bodymovinOptions = {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: animation
    }*/
    return (
        <div className={'preloader'}>
            <div className="container">
                <div className="preloader__container">
                    <Lottie className={'preloader__wrap'} animationData={animation} loop={true} />
                </div>

            </div>

           {/* <ReactBodymovin options={bodymovinOptions} />*/}
        </div>
    );
};

export default Preloader;