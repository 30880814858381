import React from 'react';

const AirDelivery = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.6669 48.3334H25.6877L45.4077 41.2344L44.6927 40.1028L21.6452 48.3334H3.3335V50.0001H46.6669V48.3334ZM0.833496 19.1673C0.833496 19.3883 0.921293 19.6003 1.07757 19.7565C1.23385 19.9128 1.44582 20.0006 1.66683 20.0006H29.1668C29.3879 20.0006 29.5998 19.9128 29.7561 19.7565C29.9124 19.6003 30.0002 19.3883 30.0002 19.1673C30.0002 18.9463 29.9124 18.7343 29.7561 18.5781C29.5998 18.4218 29.3879 18.334 29.1668 18.334H1.66683C1.44582 18.334 1.23385 18.4218 1.07757 18.5781C0.921293 18.7343 0.833496 18.9463 0.833496 19.1673Z" fill="#6699CC"/>
            <path d="M17.5007 48.3333C18.4211 48.3333 19.1673 47.5871 19.1673 46.6666C19.1673 45.7462 18.4211 45 17.5007 45C16.5802 45 15.834 45.7462 15.834 46.6666C15.834 47.5871 16.5802 48.3333 17.5007 48.3333Z" fill="#6699CC"/>
            <path d="M8.33399 3.33374H10.834V13.3336H8.33399V3.33374ZM3.33398 41.6664H20.0007V43.333H3.33398V41.6664ZM5.00065 36.6665H10.834V39.9998H5.00065V36.6665ZM12.5007 36.6665H18.334V39.9998H12.5007V36.6665Z" fill="#6699CC"/>
            <path d="M5.83366 48.3333C6.75414 48.3333 7.50033 47.5871 7.50033 46.6666C7.50033 45.7462 6.75414 45 5.83366 45C4.91318 45 4.16699 45.7462 4.16699 46.6666C4.16699 47.5871 4.91318 48.3333 5.83366 48.3333Z" fill="#6699CC"/>
            <path d="M8.3335 16.6664H29.1668C29.8299 16.6664 30.4658 16.9298 30.9346 17.3986C31.4034 17.8674 31.6668 18.5033 31.6668 19.1663C31.6668 19.8294 31.4034 20.4652 30.9346 20.9341C30.4658 21.4029 29.8299 21.6663 29.1668 21.6663H11.1785L13.5568 24.0446C14.1701 24.654 14.9989 24.9972 15.8635 24.9995H34.5977C35.3724 24.9973 36.135 25.1924 36.8135 25.5663C37.492 25.9403 38.0642 26.4808 38.476 27.137L47.126 40.8326H49.1669V13.3331H29.9718C29.3424 13.3351 28.7187 13.2122 28.1371 12.9714C27.5555 12.7307 27.0274 12.3769 26.5835 11.9306L14.6552 0H8.3335V1.66664H11.6668C11.8878 1.66664 12.0998 1.75443 12.2561 1.91071C12.4124 2.06699 12.5002 2.27895 12.5002 2.49996V14.1664C12.5002 14.3874 12.4124 14.5994 12.2561 14.7557C12.0998 14.9119 11.8878 14.9997 11.6668 14.9997H8.3335V16.6664Z" fill="#6699CC"/>
        </svg>
    );
};

export default AirDelivery;