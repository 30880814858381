import React from 'react';

const LinkArrow = () => {
    return (
        <svg width="13" height="27" viewBox="0 0 13 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 18.8296L1.34082 8.17041M12 18.8296V10.6302M12 18.8296H3.39066" stroke="black" strokeWidth="0.8212" strokeLinecap="round"/>
        </svg>
    );
};

export default LinkArrow;