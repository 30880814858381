import React, {FC} from 'react';

interface IList {
    items:React.ReactNode[];
    className?: string;
}

const List:FC<IList> = ({items,className}) => {
    return (
        <ul className={`list ${className ? className : ''}`}>
            {items.length > 0 ? items.map((item,index)=><li key={index} >{item}</li>): ''}
        </ul>
    );
};

export default List;