import React, {FC, useState} from 'react';
import ArrowDown from "../icons/ArrowDown";

interface IAccordion {
    header:React.ReactNode;
    children?:React.ReactNode;
    className?: string
}

const Accordion:FC<IAccordion> = ({header,children, className}) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={`accordion ${open ? 'active' : ''} ${className ? className : ''}`}>
            <div className="accordion__title">
                <div className={`accordion__close ${open ? 'active' : ''}`} onClick={()=>setOpen(!open)}><ArrowDown/></div>
                <div className="accordion__name" onClick={()=>setOpen(!open)}>{header}</div>
            </div>
            <div className="accordion__answer">
                {children}
            </div>
        </div>
    );
};

export default Accordion;