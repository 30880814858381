import React, {useEffect, useRef, useState} from 'react';
import List from "../UI/List";
import LinkArrow from "../icons/LinkArrow";
import ImageBlock from "../UI/ImageBlock";
import logistImg from "../../assets/images/logist.jpg";
import Button from "../UI/Button";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import InfoSection from "./InfoSection";
import ButtonSpread from "../ButtonSpread";

const LogistSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom-=100",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline.fromTo(".logist__img", UpStart, InFinish, 0.1)
        timeline.fromTo(".logist__header", UpStart, InFinish, 0.4)
        timeline.fromTo(".logist__content", UpStart, InFinish, 0.7)
        ;

    },[refTarget]);
    const [open,setOpen] = useState(false);
    return (
        <section className={'logist'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="logist__img d-none d-lg-block">
                            <ImageBlock width={529} height={434} src={logistImg}/>
                            <div className="logist__button">
                                <ButtonSpread />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="logist__wrap">
                            <div className="logist__header">
                                <div className="h2-subtitle">//vacancy</div>
                                <h2 className="h2 h2-line logist__h2"><span>LAGERMITARBEITER (W/M/D) LOGISTIK</span></h2>
                            </div>
                            <div className="logist__img d-lg-none d-block">
                                <ImageBlock width={529} height={434} src={logistImg}/>
                                <div className="logist__button">
                                    <ButtonSpread />
                                </div>
                            </div>
                            <div className="logist__content">
                                <List className={'logist__list'} items={["Zu Deinen Aufgaben gehört die Be- und Entladung der LKW´s, sowie das Kommissionieren und Verpacken von Waren.",
                                    "Du unterstützt im Warenein- und - ausgang unter Benutzung von Klammer- und Gabelstaplern, sowie Sackkarren.",
                                    "Du lagerst Waren ein, stellst diese zur Versendung bereit und buchst Artikelbewegungen mit moderner Scanner-Technik.",
                                    "Auch die Einhaltung von Qualitäts- und Sicherheitsvorschriften gehört zu Deinen Aufgaben."]} />
                                <div className="logist__more">
                                    <a className={'link-arrow'} onClick={()=>setOpen(!open)}>Mehr <LinkArrow/></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InfoSection
                active={open}
                description={'Wir bewegen Großes und sind selbst ständig in Bewegung. Damit das so bleibt, suchen wir Sie zum nächstmöglichen Zeitpunkt.  Gemeinsam machen wir Deutschlands führendes Unternehmen und innovative Logistiklösungen noch erfolgreicher.'}
                firstListHeader={'DAS ERWARTET DICH BEI UNS'}
                firstList={[
                    'Zu Deinen Aufgaben gehört die Be- und Entladung der LKW´s, sowie das Kommissionieren und Verpacken von Waren.',
                    'Du unterstützt im Warenein- und - ausgang unter Benutzung von Klammer- und Gabelstaplern, sowie Sackkarren.',
                    'Du lagerst Waren ein, stellst diese zur Versendung bereit und buchst Artikelbewegungen mit moderner Scanner-Technik.',
                    'Auch die Einhaltung von Qualitäts- und Sicherheitsvorschriften gehört zu Deinen Aufgaben.'
                ]}
                secondListHeader={'DAS BRINGST DU MIT'}
                secondList={[
                    'Idealerweise hast Du eine Ausbildung zur Fachkraft für Lagerlogistik oder zum Fachlageristen abgeschlossen.',
                    'Du bringst einen Staplerschein mit und hast bereits Erfahrung im Umgang mit Flurförderfahrzeugen.',
                    'Du kannst Dich sicher auf Deutsch verständigen und überzeugst durch eine sorgfältige Arbeitsweise.',
                    'Die Bereitschaft zur Arbeit im vorab abgestimmten Schichtdienst (Früh- und Spätdienst) runden Dein Profil ab.'
                ]}
            />
        </section>
    );
};

export default LogistSection;