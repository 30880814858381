import React from 'react';

const Close = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 0.75L11.75 11.25M1.25 11.25L11.75 0.75" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    );
};

export default Close;