import React, {FC} from 'react';

interface IIconCard{
    className?:string;
    img?: React.ReactNode;
    title?: React.ReactNode;
    description?: React.ReactNode;
    testRef?: any
}

const IconCard:FC<IIconCard> = ({className, img, title, description,testRef}) => {
    return (
        <div ref={testRef} className={`icon-card ${className ? className: ''}`}>
            <div className="icon-card__content">
                {img && <div className="icon-card__img">{img}</div>}
                {title && <div className="icon-card__header">{title}</div>}
                {description && <div className="icon-card__description">{description}</div>}
            </div>
        </div>
    );
};

export default IconCard;