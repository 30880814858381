import React, {FC} from 'react';
import Close from "../icons/Close";
import Button from "../UI/Button";
import Accordion from "../UI/Accordion";
import Switch from "../UI/Switch";
import {Link} from "react-router-dom";

interface IModal {
    active: boolean;
    onClose: () => void;
}

const ModalCookies:FC<IModal> = ({active,onClose}) => {
    return (
        <div className={`modal-cookies ${active ? "active": ""}`}>
            <div className="modal-cookies__wrap">
                <div className="modal-cookies__close" onClick={onClose}><Close/></div>
                <div className="modal-cookies__title">Cookie einstellungen</div>
                <div className="modal-cookies__body">
                    <div className="modal-cookies__header">Cookie Nutzung</div>
                    <div className="modal-cookies__description">
                        Bitte treffen Sie Ihre Auswahl und bestätigen Sie diese durch Klicken auf die entsprechenden Schaltflächen. Weitere Informationen finden Sie in unserer <a
                        href="adr/src/components">Datenschutzerklärung</a>.
                    </div>
                    <div className="modal-cookies__content">
                        <div className="modal-cookies__items">
                            <div className="modal-cookies__item">
                                <Accordion header={'Notwendige Cookies'} >
                                    Diese Cookies sind für die grundlegenden Funktionen der Website erforderlich und können nicht deaktiviert werden. Sie speichern z.B. Ihre Cookie-Einstellungen.
                                </Accordion>
                                <Switch isChecked={true} className={'modal-cookies__switch'} label={'strictly'}/>
                            </div>
                            <div className="modal-cookies__item">
                                <Accordion header={'Analytische Cookies'} >
                                    Diese Cookies ermöglichen es uns, die Nutzung der Website zu analysieren und zu verbessern. Sie helfen uns zu verstehen, wie Besucher mit der Website interagieren und welche Inhalte am beliebtesten sind.
                                </Accordion>
                                <Switch isChecked={true} className={'modal-cookies__switch'} label={'performance'}/>
                            </div>
                            <div className="modal-cookies__item">
                                <Accordion header={'Marketing Cookies'} >
                                    Diese Cookies werden von Drittanbietern verwendet, um Ihnen personalisierte Werbung zu zeigen, die auf Ihre Interessen abgestimmt ist. Sie können diese Cookies deaktivieren, wenn Sie keine personalisierte Werbung wünschen.
                                </Accordion>
                                <Switch isChecked={true} className={'modal-cookies__switch'} label={'advertisement'}/>
                            </div>
                        </div>
                        <div className="modal-cookies__info">
                            <div className="modal-cookies__info-header"><Link to="/datenschutz">Weitere Informationen</Link></div>
                            <div className="modal-cookies__info-description">
                            </div>
                            <div className="modal-cookies__buttons">
                                <div className="modal-cookies__confirm">
                                    <Button onClick={onClose} className={'btn-small modal-cookies__btn'} name={'Alle akzeptieren'}/>
                                    <Button onClick={onClose} className={'btn-small-white modal-cookies__btn'} name={'Alle ablehnen'}/>
                                </div>
                                <div className="modal-cookies__save">
                                    <Button onClick={onClose} className={'btn-small-white modal-cookies__btn'} name={'Einstellungen speichern'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCookies;