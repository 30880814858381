import React from 'react';
/*import Lottie from "lottie-react";
import animation from "../assets/effects/Intro.json";*/
import myVideo from "../assets/intro.mp4";
const Intro = () => {
    return (
        <div className={'intro'}>
            <video autoPlay muted>
                <source src={myVideo} type="video/mp4" />
            </video>
            {/*<Lottie animationData={animation} loop={false} />*/}
        </div>
    );
};

export default Intro;