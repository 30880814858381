import React, {useEffect, useRef, useState} from 'react';
import List from "../UI/List";
import LinkArrow from "../icons/LinkArrow";
import ImageBlock from "../UI/ImageBlock";
import disponentImg from "../../assets/images/disponent.jpg";
import Button from "../UI/Button";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import InfoSection from "./InfoSection";
import ButtonSpread from "../ButtonSpread";

const DisponentSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom-=100",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline.fromTo(".disponent__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".disponent__img", UpStart, InFinish, 0.4)
        timeline.fromTo(".disponent__content", UpStart, InFinish, 0.7)
        ;

    },[refTarget]);
    const [open,setOpen] = useState(false);
    return (
        <section className={'disponent'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="disponent__wrap">
                            <div className="disponent__header">
                                <div className="h2-subtitle">//vacancy</div>
                                <h2 className="h2 h2-line disponent__h2"><span>DISPONENT (W/M/D)</span></h2>
                            </div>
                            <div className="disponent__img d-block d-lg-none">
                                <ImageBlock width={529} height={434} src={disponentImg}/>
                                <div className="disponent__button">
                                    <ButtonSpread />
                                </div>
                            </div>
                            <div className="disponent__content">
                                <List className={'disponent__list'} items={["Zu Ihrem Tagesgeschäft gehören der Einkauf und die Disposition von Fahrzeugen - inklusive des Zusammenstellens von Touren mehrerer Ladungen und der Erstellung von Dispoplänen.",
                                    "Neben der Laderaum-Akquise am Frachtmarkt kümmern Sie sich auch um die Erstellung von Angeboten und Transportlösungskonzepten.",
                                    "Mit einem geschulten Blick für Details wickeln Sie eingehende Aufträge ab - von der Erfassung über die Kontrolle der Abholung und Zustellung bis hin zur Abrechnung.",
                                    "Sie sind kompetente Ansprechperson für unsere internationalen Kunden, Partner und Unternehmer, mit denen Sie eng und vertrauensvoll zusammenarbeiten.",
                                    "Nicht zuletzt sind Sie verantwortlich für die Sicherstellung der internen und externen Qualitätsanforderungen."]} />
                                <div className="disponent__more">
                                    <a className={'link-arrow'} onClick={()=>setOpen(!open)}>Mehr <LinkArrow/></a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="disponent__img-wrapper">
                            <div className="disponent__img d-none d-lg-block">
                                <ImageBlock width={529} height={434} src={disponentImg}/>
                                <div className="disponent__button">
                                    <ButtonSpread />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <InfoSection
                active={open}
                description={'Freuen Sie sich auf eine abwechslungsreiche und verantwortungsvolle Tätigkeit und ein großartiges Team, das Sie herzlich aufnimmt und Ihnen hilfsbereit zur Seite steht. Zudem erwartet Sie ein umfassendes Onboarding, bei dem Sie alle relevanten Schnittstellen und Ansprechpersonen kennenlernen, um sich von Tag eins ein großes Netzwerk aufzubauen. Unsere Zusammenarbeit zeichnet sich aus, dass wir schnelle Absprachen treffen und fördern, um unseren Mitarbeitenden eigeninitiatives Handeln zu ermöglichen. Wir bieten Ihnen vielseitige Möglichkeiten, sich weiterzubilden, und fördern damit Ihre Karriereentwicklung.'}
                firstListHeader={'DAS ERWARTET SIE BEI UNS'}
                firstList={[
                    'Zu Ihrem Tagesgeschäft gehören der Einkauf und die Disposition von Fahrzeugen - inklusive des Zusammenstellens von Touren mehrerer Ladungen und der Erstellung von Dispoplänen.',
                    'Neben der Laderaum-Akquise am Frachtmarkt kümmern Sie sich auch um die Erstellung von Angeboten und Transportlösungskonzepten.',
                    'Mit einem geschulten Blick für Details wickeln Sie eingehende Aufträge ab - von der Erfassung über die Kontrolle der Abholung und Zustellung bis hin zur Abrechnung.',
                    'Sie sind kompetente Ansprechperson für unsere internationalen Kunden, Partner und Unternehmer, mit denen Sie eng und vertrauensvoll zusammenarbeiten.',
                    'Nicht zuletzt sind Sie verantwortlich für die Sicherstellung der internen und externen Qualitätsanforderungen.'
                ]}
                secondListHeader={'DAS BRINGEN SIE MIT'}
                secondList={[
                    'Ihre Basis ist eine kaufmännische Ausbildung idealerweise zur Kauffrau für Spedition und Logistikdienstleistung.',
                    'Zudem bringen Sie Berufserfahrung in der Disposition von internationalen Lkw-Verkehren mit.',
                    'Im Umgang mit MS-Office sowie gängigen Speditionsprogrammen sind Sie versiert und zeichnen sich durch einen selbstständigen Arbeitsstil aus.',
                    'Ihre guten Sprachkenntnisse in Englisch machen Sie zu dem perfekten Kandidaten.',
                    'Als zuverlässiger und durchsetzungsstarker Teamplayer zeichnen Sie sich durch Organisations- und Kommunikationsgeschick aus.'
                ]}
            />
        </section>
    );
};

export default DisponentSection;