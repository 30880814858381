import React, {useEffect, useRef} from 'react';
import IconCard from "../UI/IconCard";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {numberWithCommas} from "../../helpers/scripts"
const IndicatorSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const targetSection = useRef(null);

    const block1Ref = useRef<HTMLDivElement>(null);
    const block2Ref = useRef<HTMLDivElement>(null);
    const block3Ref = useRef<HTMLDivElement>(null);
    const block4Ref = useRef<HTMLDivElement>(null);
    const block5Ref = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger:targetSection.current,
                start: "top bottom-=100",
            }
        });
        const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.3};
        const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.3};
        timeline.fromTo(".indicator__header .h2", UpStart, InFinish, 0.1)
        timeline.fromTo(".indicator__wrap", UpStart, InFinish, 0.4)

        const newItems = [block1Ref.current?.querySelector('.icon-card__header'),
            block2Ref.current?.querySelector('.icon-card__header'),
            block3Ref.current?.querySelector('.icon-card__header'),
            block4Ref.current?.querySelector('.icon-card__header'),
            block5Ref.current?.querySelector('.icon-card__header')];

           timeline.from(newItems, {
                textContent: 0,
                duration: 2,
                ease: "power1.in",
                snap: { textContent: 1 },
                stagger: {
                    each: 0,
                    onUpdate: function() {
                        // @ts-ignore
                        this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent))+"+";
                    },
                }
            });
    },[targetSection]);



    return (
        <section className={'indicator'} ref={targetSection}>
            <div className="container container-large">
                <div className="indicator__header">
                    <h2 className="h2"><span>Erfolgskennzahlen</span></h2>
                </div>
                <div className="indicator__wrap">
                    <IconCard testRef={block1Ref} className={'indicator__item'} title={'50000'} description={'Sendungen pro Jahr'}  />
                    <IconCard testRef={block2Ref} className={'indicator__item indicator__item-red'} title={'50'} description={'zufriedene Stammpartnern'}  />
                    <IconCard testRef={block3Ref} className={'indicator__item'} title={'200000'} description={'tonnen pro Jahr'}  />
                    <IconCard testRef={block4Ref} className={'indicator__item'} title={'12'} description={'Jahre auf dem Markt'}  />
                    <IconCard testRef={block5Ref} className={'indicator__item'} title={'15'} description={'LKWs'}  />
                    <IconCard className={'indicator__item'} title={'ganz'} description={'Europa'}  />
                </div>
            </div>
        </section>
    );
};

export default IndicatorSection;