import React, { FC, useState } from 'react';

interface IItem{
    value: string;
    name: string;
}

interface ISelect {
    name?: string;
    placeholder?: string;
    className?: string;
    items: IItem[];
    defaultValue?: string;
    onChange?: (value:string)=> void;
}

const Select:FC<ISelect> = ({name,placeholder,className,items, defaultValue, onChange= ()=>{}}) => {
    const [open,setOpen] = useState(false);
    const [select, setSelect] = useState(defaultValue ? items.filter(item=>item.value === defaultValue)[0].name : '');
    const onSelect = (value:IItem)=>{
        onChange(value.value);
        setSelect(value.name);
        setOpen(false);
    };//
    return (
        <div className={`select ${open ? 'active' : ''} ${className ? className : ''}`}>
            <div className={`select__active`} onClick={()=>setOpen(!open)}>
                <div className={'select__label'}>{placeholder ? placeholder :'Auswählen'}</div>
                <div className={'select__current'}>{select ? select :'Auswählen'}</div>
                <div className="select__arrow">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 1L6.5 6L1.5 1" stroke="#C3C4C5" strokeWidth="1.06667" strokeLinecap="square"/>
                    </svg>
                </div>
            </div>
            <div className="select__items">
                {items && items.map((item,index)=><div key={index} className={`select__item ${item.value === defaultValue ? 'active' : ''}`} onClick={()=>onSelect(item)}>{item.name}</div>)}
            </div>
        </div>
    );
};

export default Select;