import React, {useEffect, useRef} from 'react';
import Button from "../UI/Button";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {gtagEvent} from "../../helpers/scripts";

const JoinTeamSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.5, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.5, stagger: 0.5};
        timeline.fromTo(".join-team__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".join-team__content", UpStart, InFinish, 0.4)
        ;

    },[refTarget]);
    return (
        <section className={'join-team'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="join-team__header">
                            <div className="h2-subtitle">//JoinTheTeam</div>

                            <h2 className={'h2'}><span>Werden Sie Teil</span> unseres Teams!</h2>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="join-team__content">
                            <Button onClick={()=>{gtagEvent('click','contact_per_email')}} type={'htmlLink'} href={"mailto:info@adr-trans.de"} className={'btn-small join-team__btn'} name={'Kontakt per E-Mail'}/>
                            <Button onClick={()=>{gtagEvent('click','contact_per_whatsapp')}} type={'htmlLink'} href={"https://wa.me/49017670066644"} className={'btn-small join-team__btn'} name={'Kontakt per Whatsapp'}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JoinTeamSection;