import React, {FC, useState} from 'react';
import Close from "../icons/Close";

interface SwitchProps {
    className?: string;
    label: string;
    isChecked?: boolean;
    onChange?: (isChecked: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({className, label, isChecked=false, onChange=()=>{} }) => {
    const [checked, setChecked] = useState<boolean>(isChecked);

    const handleChange = () => {
        setChecked(!checked);
        onChange(!checked);
    };

    return (
        <div className={`switch ${className ? className : ""}`}>

            <input name={label} checked={checked} onChange={handleChange} type="checkbox" />
            <label onClick={handleChange} htmlFor={label}>
                <div className="switch__marks">
                    <div className="switch__mark-active">
                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 5L4.5 8.5L11 1" stroke="#FDFFFE" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="switch__mark-disabled"><Close/></div>
                </div>
            </label>
        </div>
    );
};

export default Switch;