import React, {useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import CardItem from "../UI/CardItem";

import img1 from '../../assets/images/slider/1.jpg'
import img2 from '../../assets/images/slider/2.jpg'
import img3 from '../../assets/images/slider/3.jpg'
import Arrow from "../icons/Arrow";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";


const SliderSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.1};
        const InFinish = {y:0,opacity:1,duration: 0.5,clearProps: "all", stagger: 0.2};
        timeline.fromTo(".slider-section__title", UpStart, InFinish, 0.1)
        timeline.fromTo(".slider-section__controls", UpStart, InFinish, 0.4)
        timeline.fromTo(".slider-section__description", UpStart, InFinish, 0.7)
        timeline.fromTo(".full-slider .card-item", UpStart, InFinish, 1.0)
        ;

    },[refTarget]);


    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        variableWidth: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    const sliderRef = useRef<any>(null);
    const [arrowNext, setArrowNext] = useState<boolean>(false);
    const [arrowPrev, setArrowPrev] = useState<boolean>(true);
    const [totalSlides, setTotalSlides] = useState<number>(0);

    useEffect(()=>{


        function handleResize(){
            setTimeout(()=>{
                const sliderInstance = sliderRef.current?.innerSlider;
                const currentSlidesToShow = sliderInstance?.props?.slidesToShow;
                const totalSlidesCurrent = sliderRef.current?.innerSlider?.state?.slideCount - currentSlidesToShow;
               // console.log('totalSlides',totalSlides,totalSlidesCurrent,window.innerWidth)
                if(totalSlidesCurrent !== totalSlides) {
                    setTotalSlides(totalSlidesCurrent);
                }
            },1000);
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    },[totalSlides])

    useEffect(()=>{
        const sliderInstance = sliderRef.current?.innerSlider;
        const currentSlidesToShow = sliderInstance?.props?.slidesToShow;
        const totalSlidesCurrent = sliderRef.current?.innerSlider?.state?.slideCount - currentSlidesToShow;
        setTotalSlides(totalSlidesCurrent);
    },[sliderRef])
    //const totalSlides = sliderRef.current?.innerSlider?.state?.slideCount - currentSlidesToShow;

    const changeSlide = (direction: "prev" | "next") => {
        const currentSlide = sliderRef.current?.innerSlider.state.currentSlide;

        if (direction === "prev") {
            if (currentSlide > 0) {
                sliderRef.current.slickPrev();
            }
            setArrowPrev(currentSlide - 1 <= 0);
            setArrowNext(currentSlide - 1 >= totalSlides);
        } else {
            if (currentSlide < totalSlides) {
                sliderRef.current.slickNext();
            }
            setArrowPrev(currentSlide + 1 <= 0);
            setArrowNext(currentSlide + 1 >= totalSlides);
        }
    };

    return (
        <section className={'slider-section'} ref={refTarget}>
            <div className="container">
                <div className="slider-section__header">
                    <div className="slider-section__title">
                        <div className="h2-subtitle">//cars</div>
                        <h2 className={'h2 h2-line'}><span>Unser Fuhrpark</span></h2>
                    </div>
                    <div className="slider-section__controls">
                        {totalSlides > 0 &&
                            <div className="slider-arrows">
                                <div onClick={()=>changeSlide('prev')} className={`slider-arrow slider-arrow__prev ${arrowPrev ? "disabled" : ""}`}>
                                    <Arrow/>
                                </div>
                                <div onClick={()=>changeSlide('next')} className={`slider-arrow slider-arrow__next ${arrowNext ? "disabled" : ""}`}>
                                    <Arrow/>
                                </div>
                            </div>
                        }

                    </div>
                </div>

                <div className="slider-section__wrap">
                    <div className="slider-section__description">
                        Unsere modernen Fahrzeuge und geschulten Fahrer sind das Herzstück unseres Logistikbetriebs. Wir garantieren Ihnen eine zuverlässige und sichere Beförderung Ihrer Fracht durch unsere top-moderne Flotte und erfahrenen Fahrer."
                    </div>
                    <Slider ref={sliderRef} {...settings} className="full-slider">
                        <div>
                            <CardItem title={'Moderne Fahrzeuge'} description={'Die Flotte ist modern und ständig gewartet'} image={img1}/>
                        </div>
                        <div>
                            <CardItem title={'Regelmäßige Inspektionen'} description={'Die Fahrzeuge werden regelmäßig inspiziert, um ihre Leistung und Sicherheit zu gewährleisten'} image={img2}/>
                        </div>
                        <div>
                            <CardItem title={'Erfahrene Fahrer'} description={'Die Fahrer sind geschult und erfahren im Transportwesen'} image={img3}/>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default SliderSection;