import React from 'react';

const Puzzle = () => {
    return (
        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.7646 24.9779C42.8161 25.949 42.5669 26.9115 42.0513 27.7279C41.7505 28.2177 41.8279 28.5185 42.2404 28.8708C42.6529 29.2232 43.0052 29.6271 43.3747 30.0052C44.0021 30.6411 44.0021 31.0536 43.3661 31.6896C39.499 35.5654 35.6318 39.4326 31.756 43.3083C31.4638 43.6005 31.1802 43.8841 30.888 44.1677C30.2521 44.7779 29.8224 44.7779 29.1865 44.1419C27.0896 42.0451 24.9927 39.9568 22.8958 37.8599L16.2013 31.1654C15.3505 30.3146 15.3505 30.0396 16.1755 29.1974C16.6396 28.7247 17.1122 28.2521 17.5849 27.788C18.2552 27.1349 18.5646 27.1263 19.295 27.7021C21.2372 29.2318 23.8154 28.8794 25.156 26.8943C26.1357 25.4505 26.0497 23.6287 24.9411 22.2279C24.2193 21.3083 24.2279 21.1365 25.0786 20.2943C26.17 19.2029 27.2615 18.1115 28.3529 17.0115C29.2294 16.1349 29.4958 16.1263 30.3638 16.9857C31.3349 17.9482 32.3232 18.9107 33.2685 19.899C33.6122 20.2599 33.8529 20.3115 34.2911 20.0536C37.7888 18.0685 42.1029 20.1568 42.756 24.1357C42.8076 24.4193 42.7646 24.7029 42.7646 24.9779ZM10.581 0.631775H19.9224C20.8677 0.640369 21.1169 0.880994 21.1169 1.80912V3.80287C21.1169 4.59349 20.8505 4.86849 20.0599 4.92865C18.281 5.05756 16.6911 6.39818 16.3904 8.0224C16.0294 9.99037 16.8888 11.7263 18.6677 12.5083C19.1318 12.7146 19.656 12.7919 20.1544 12.8607C20.799 12.9466 21.1083 13.2388 21.1169 13.8919C21.1255 15.7826 21.1255 17.6732 21.1169 19.5638C21.1169 20.2771 20.7732 20.6122 20.0427 20.6122C18.9513 20.6208 17.8513 20.6208 16.7599 20.6122C16.3732 20.6036 16.2099 20.7326 16.1068 21.1279C15.2732 24.1701 12.5575 25.9404 9.47239 25.313C7.27239 24.8662 5.78567 23.474 5.16692 21.274C5.03802 20.8013 4.84895 20.5951 4.34192 20.6122C3.33645 20.6466 2.32239 20.6208 1.31692 20.6208C0.371611 20.6208 0.165361 20.4146 0.156767 19.4951V1.64584C0.139579 0.820837 0.337236 0.631775 1.17942 0.631775H10.581Z" fill="#1C3D76"/>
        </svg>
    );
};

export default Puzzle;