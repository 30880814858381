import React, {useEffect, useRef} from 'react';
import ImageBlock from "../UI/ImageBlock";
import ecoImg from "../../assets/images/ecology.jpg"
import List from '../UI/List';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const EcologySection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline.fromTo(".ecology__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".ecology__content", UpStart, InFinish, 0.4)
        timeline.fromTo(".ecology__img", UpStart, InFinish, 0.7)
        timeline.fromTo(".ecology__list", UpStart, InFinish, 1.0)
        ;

    },[refTarget]);
    return (
        <section className={'ecology'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-none d-lg-block">
                        <div className="ecology__img">
                            <ImageBlock type={'left'} width={529} height={434} src={ecoImg} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ecology__wrap">
                            <div className="ecology__header">
                                <div className="h2-subtitle">//ecology</div>
                                <h2 className="h2 h2-line"><span>Ökologie</span></h2>
                            </div>

                            <div className="ecology__content">
                                Wir setzen uns aktiv für nachhaltige und umweltfreundliche Transportlösungen ein. Durch den Einsatz modernster Fahrzeuge mit geringem CO2-Ausstoß, effizientes Routenmanagement und kontinuierliche Prozessoptimierung tragen wir zur Reduzierung von Emissionen und zum Schutz der Umwelt bei.
                            </div>
                            <div className="ecology__img d-lg-none d-block">
                                <ImageBlock type={'left'} width={529} height={434} src={ecoImg} />
                            </div>
                            <div className="ecology__list">

                                <List items={[
                                    <>Moderne Fahrzeuge mit geringem CO2-Ausstoß und hoher Energieeffizienz.</>,
                                    "Effizientes Routenmanagement zur Reduzierung von Kilometern und Treibstoffverbrauch.",
                                    <>Verwendung von umweltfreundlichen Verpackungsmaterialien zur Verringerung des ökologischen Fußabdrucks.</>,
                                    "Kontinuierliche Verbesserung der betrieblichen Prozesse hinsichtlich Umweltverträglichkeit."
                                ]}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EcologySection;