import {createSlice} from "@reduxjs/toolkit";


interface appState {
    openMenu: boolean;
    modalCookies: boolean;
    modalSuccess: boolean;
    modalErrorMessage: boolean;
    preloader: boolean;
    intro: boolean;
    isLoading: boolean;
    error: string
}

const initialState: appState = {
    isLoading: false,
    preloader: true,
    intro: true,
    modalSuccess: false,
    modalCookies: false,
    modalErrorMessage: false,
    openMenu: false,
    error: ''
};


const appSlice = createSlice({
    name:'appSlice',
    initialState,
    reducers:{
        changeOpenMenu(state,action){
            state.openMenu = action.payload;
        },
        changeModalCookies(state,action){
            state.modalCookies = action.payload;
        },
        changeModalSuccess(state,action){
            state.modalSuccess = action.payload;
        },
        changeModalErrorMessage(state){
            state.modalErrorMessage = !state.modalErrorMessage;
        },
        changePreloader(state,action){
            state.preloader = action.payload;
        },
        changeIntro(state,action){
            state.intro = action.payload;
        },
    }
});

export const {changeIntro,changeOpenMenu,changeModalCookies,changeModalSuccess,changeModalErrorMessage,changePreloader} = appSlice.actions;
export default appSlice.reducer;