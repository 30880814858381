import React, {useEffect, useRef} from 'react';
import ImageBlock from "../UI/ImageBlock";
import teamImg from "../../assets/images/team.jpg"
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

const TeamSection = () => {

    const refTarget = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    useEffect(()=>{
        let timeline = gsap.timeline({
     /*       scrollTrigger:{
                trigger: refTarget.current,
               // start: "top top",
                //markers:true
            },*/

        });
        const UpStart = {opacity:0,duration: 0.5, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.5, stagger: 0.5};
        timeline.fromTo(".team__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".team__img", UpStart, InFinish, 0.3)
        timeline.fromTo(".team__content", UpStart, InFinish, 0.6)
        ;

    },[refTarget]);
    return (
        <section className={'team'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="team__wrap">
                            <div className="team__header">
                                <div className="h2-subtitle">//team</div>
                                <h2 className="h2 h2-line team__h2"><span>Team</span></h2>
                            </div>
                            <div className="team__img d-lg-none d-block">
                                <ImageBlock width={529} height={434} src={teamImg}/>
                            </div>
                            <div className="team__content">
                                Unser engagiertes und kompetentes Team ist der Schlüssel zu unserem Erfolg.
                                Unsere Mitarbeiter sind hochqualifiziert und verfügen über langjährige Erfahrung in der Logistikbranche.
                                Wir setzen auf eine offene Kommunikation und Zusammenarbeit, um individuelle Lösungen für jeden Kunden zu entwickeln.
                                Unser Ziel ist es, Ihnen einen erstklassigen Service zu bieten und Ihre Anforderungen in jeder Hinsicht zu erfüllen.
                                Lernen Sie unser Team kennen und erfahren Sie, wie wir Ihnen dabei helfen können, Ihre Logistikprozesse zu optimieren.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="team__img d-none d-lg-block">
                            <ImageBlock width={529} height={434} src={teamImg}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamSection;