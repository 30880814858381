import React, {useEffect, useRef, useState} from 'react';
import Layout from "../components/sections/Layout";
import TeamSection from "../components/sections/TeamSection";
import JoinTeamSection from "../components/sections/JoinTeamSection";
import DriverSection from "../components/sections/DriverSection";
import LogistSection from "../components/sections/LogistSection";
import DisponentSection from "../components/sections/DisponentSection";
import InfoSection from "../components/sections/InfoSection";
import ContactSection from "../components/sections/ContactSection";

import Preloader from "../components/Preloader";
import teamImg from "../assets/images/team.jpg"
import driverImg from "../assets/images/driver.jpg"
import logistImg from "../assets/images/logist.jpg";
import disponentImg from "../assets/images/driver.jpg";



import usePreloader from "../hooks/usePreloader";

const Jobs = () => {
    const images = [
        teamImg,
        driverImg,
        logistImg,
        disponentImg,
    ];

    const isLoading = usePreloader(images);
    return (
        <>
            {isLoading && <Preloader/>}
                <Layout>
                    <div className={'wrapper'} >
                        <TeamSection/>
                        <JoinTeamSection/>
                        <DriverSection/>
                        <LogistSection/>
                        <DisponentSection/>

                        <ContactSection/>
                    </div>
                </Layout>
        </>

    );
};

export default Jobs;