import React, {FC} from 'react';

interface ICardItem{
    title: string;
    description: string;
    image: string;
}

const CardItem:FC<ICardItem> = ({title,description,image}) => {
    return (
        <div className={'card-item'}>
            <div className="card-item__img">
                <img src={image} alt=""/>
            </div>
            <div className="card-item__header">{title}</div>
            <div className="card-item__description">{description}</div>
        </div>
    );
};

export default CardItem;