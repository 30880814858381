import React, {useEffect, useRef} from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import Map from "../Map"

const MapSection = () => {
    gsap.registerPlugin(ScrollTrigger);
    const refTarget = useRef(null);

    useEffect(()=>{
        let timeline = gsap.timeline({
            scrollTrigger:{
                trigger: refTarget.current,
                start: "top bottom",
                //markers:true
            },

        });
        const UpStart = {opacity:0,duration: 0.3, stagger: 0.5};
        const InFinish = {opacity:1,duration: 0.3, stagger: 0.5};
        timeline.fromTo(".map-section__header", UpStart, InFinish, 0.1)
        timeline.fromTo(".map-section__map", UpStart, InFinish, 0.4)
        timeline.fromTo(".map-section__content", UpStart, InFinish, 0.3)
        ;

    },[refTarget]);
    return (
        <section className={'map-section'} ref={refTarget}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="map-section__wrap">
                            <div className="map-section__header">
                                <div className="h2-subtitle">//infrastructure</div>
                                <h2 className="h2 h2-line"><span>Infrastruktur</span></h2>
                            </div>
                            <div className="map-section__map d-flex d-sm-none">
                              {/*  <Map position={{lat: 51.361813216545016, lng: 6.572742462158204}} zoom={1}/>*/}
                                <Map position={[51.361813216545016, 6.572742462158204]} zoom={13}/>
                            </div>
                            <div className="map-section__content">
                                Unsere Logistikinfrastruktur umfasst ein Netzwerk von Standorten in ganz Europa und wird ständig erweitert.
                                Mit hochmodernen Lagerhäusern, einer top-modernen Flotte und erfahrenen Mitarbeitern stellen wir sicher,
                                dass Ihre Fracht sicher und effizient transportiert wird. Unsere Expansionsstrategie ermöglicht es uns,
                                unseren Kunden einen noch breiteren Servicebereich anzubieten und schnell auf die sich ändernden
                                Anforderungen des Marktes zu reagieren. Vertrauen Sie auf unsere expandierende Infrastruktur für einen
                                reibungslosen und effizienten Transport Ihrer Fracht.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">

                        <div className="map-section__map d-none d-sm-flex">
                            <Map position={[51.361813216545016, 6.572742462158204]} zoom={13}/>
                        {/*    <iframe style={{border:'none'}}
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83998.7645753635!2d2.2769955010472684!3d48.85894658075173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e1f06e2b70f%3A0x40b82c3688c9460!2z0J_QsNGA0LjQtiwg0KTRgNCw0L3RhtC40Y8!5e0!3m2!1sru!2sua!4v1680280550583!5m2!1sru!2sua"
                                width="600" height="450"allowFullScreen loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MapSection;